
const transitionFinancingAndCreditsSubTabKeys: Record<string, string> = {
    additionality: 'additionality',
    transitionCreditCashflow: 'transition-credit-cashflow',
    projectDocument: 'project-document',
    justTransition: 'just-transition',
    sdgGoals: 'sdg-goals',
    reporting: 'reporting',
};

export default transitionFinancingAndCreditsSubTabKeys;
