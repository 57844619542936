import { initialStateApi } from '@/Redux/utils';
import { createSlice } from '@reduxjs/toolkit';
const CardInfo = createSlice({
    name: 'cards',
    initialState: initialStateApi,
    reducers: {
        setCardInfo: (state, action) => {
            state.response = action.payload;
        },
    },
});

export const { setCardInfo } = CardInfo.actions;

export default CardInfo.reducer;
