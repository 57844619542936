import { addQueryParams } from '@/api/helper';
import api from '../../../../api';
import {
    createSlice,
    createAsyncThunk,
    SerializedError,
    PayloadAction,
    ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import {
    APILoadingIdentifiers,
    getPowerGenerationData,
} from '../powerGeneration/powerGeneration.slice';
import { RootState } from '@/Redux/store';
import { toast } from 'react-toastify';
import { getProfitAnalysisData } from '../profitAnalysis/getProfitAnalysis.slice';
import { message } from 'antd';

export enum SensitivityAnalysisIdentifiers {
    GROSS_POWER_SAVE = 'grossPowerSave',
    AUX_SAVE = 'auxSave',
    EMISSION_SAVE = 'emissionSave',
    WACC_SAVE = 'waccSave',
    VARIABLE_FIXED_TARIFF_SAVE = 'variableFixedTariffSave',
    TOTAL_TARIFF_SAVE = 'totalTariffSave',
    FUEL_COSTS_SAVE = 'fuelCostSave',
    OM_EXPENSE_SAVE = 'omExpenseSave',
    WDV_SAVE = 'wdvSave',
    CORPORATE_TAX_SAVE = 'corporateTaxSave',
    DEBT_SCHEDULE_SAVE = 'debtScheduleSave',
    SALVAGE_SAVE = 'salvageSave',
    WORKING_CAPITAL_SAVE = 'workingCapitalSave',
    CAPEX_SAVE = 'capexSave',
    EMISSIONFACTOR_DELETE = 'emissionFactorDelete',
}

export enum RefetchOptions {
    PowerGenData = 'PowerGenData',
    RevenueExpenseData = 'RevenueExpenseData',
    None = 'None',
}

type InitialStateType = {
    isLoading: { [key in SensitivityAnalysisIdentifiers]?: boolean };
    isError: boolean;
    response: any | null;
    errorResponse: SerializedError | any | null;
};

type FetchResponse = {
    result: any;
    identifier: SensitivityAnalysisIdentifiers;
};

type FetchError = {
    error: SerializedError | any;
    identifier: SensitivityAnalysisIdentifiers;
};

type ThunkApiConfig = {
    rejectValue: FetchError;
    state: RootState;
};

type SensitivityAnalysisSaveParams = {
    data: any;
    identifier: SensitivityAnalysisIdentifiers;
    queryParams: {
        type: 'emissions' | 'gross_power' | 'financials' | 'cashFlows' | 'dcf';
    };
    refetchForYear?: number;
    refetch?: boolean;
    sensitivityAnalysis?: any;
};

const initialStateApi: InitialStateType = {
    isLoading: {},
    isError: false,
    response: null,
    errorResponse: null,
};

export const sensitivityAnalysisSave = createAsyncThunk<
    FetchResponse,
    SensitivityAnalysisSaveParams,
    ThunkApiConfig
>(
    'sensitivityAnalysisSave',
    async (
        { data, identifier, queryParams, refetchForYear, refetch, sensitivityAnalysis },
        { rejectWithValue, dispatch, getState }
    ) => {
        try {
            const url = addQueryParams(api.config.saveSensitivityAnalysis(), queryParams);
            const response = await fetch(url, {
                ...api.http.patch(data),
            });
            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            api.afterFetchHandlers.checkStatus(response);

            const state = getState();
            const financialYear =
                state?.powerGenerationData?.response?.data?.financials?.financialYear;
            const powerGenYear = state?.powerGenerationData?.response?.data?.year;

            if (refetch || refetchForYear) {
                await dispatch(
                    getPowerGenerationData({
                        data: {
                            year: refetchForYear || powerGenYear,
                            financialYear,
                            senstivityAnalysis: { ...sensitivityAnalysis },
                        },
                        identifier: APILoadingIdentifiers.INITIAL_GET,
                    })
                );
                await dispatch(getProfitAnalysisData());
            }

            return { result, identifier };
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue({ error, identifier });
        }
    }
);

const sensitivityAnalysis = createSlice({
    name: 'sensitivityAnalysisSave',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
        builder
            .addCase(sensitivityAnalysisSave.pending, (state, action) => {
                state.isLoading[action.meta.arg.identifier] = true;
            })
            .addCase(
                sensitivityAnalysisSave.fulfilled,
                (state, action: PayloadAction<FetchResponse>) => {
                    state.isLoading[action.payload.identifier] = false;
                    state.response = action.payload.result;
                    toast.success('Changes saved successfully');
                }
            )
            .addCase(sensitivityAnalysisSave.rejected, (state, action) => {
                const { identifier } = action.meta.arg as {
                    identifier: SensitivityAnalysisIdentifiers;
                };
                toast.error('Something went wrong!');
                state.isLoading[identifier] = false;
                state.errorResponse = action.payload?.error || action.error;
            });
    },
});

export default sensitivityAnalysis.reducer;
