import api from '@/api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '../../../utils';
import { toast } from 'react-toastify';

type payloadType = {
    apiEndPoint: string;
    payload: Record<string, any>;
};

export const addDataForCleanEnergyAction: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'addDataForCleanEnergyAction',
    async ({ apiEndPoint, payload }, { rejectWithValue }) => {
        try {
            const response = await fetch(apiEndPoint, api.http.post(payload));

            const result = await api.afterFetchHandlers.checkStatus(response)
            return result;

        } catch (error: any) {
            const result = await error.json();
            
            return rejectWithValue(result.message || 'Failed to save data');
        }
    }
);

const addDataForCleanEnergy = createSlice({
    name: 'addDataForCleanEnergy',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(addDataForCleanEnergyAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(addDataForCleanEnergyAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(addDataForCleanEnergyAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
                state.isError = true;
            });
    },
});

export default addDataForCleanEnergy.reducer;
