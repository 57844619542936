import { addQueryParams } from '@/api/helper';
import api from '../../../../api';
import {
    createSlice,
    createAsyncThunk,
    SerializedError,
    PayloadAction,
    ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
    APILoadingIdentifiers,
    getPowerGenerationData,
} from '../powerGeneration/powerGeneration.slice';
import { getfinancialAssets } from '../financialAssets/financialAssets.slice';
import { AppDispatch, RootState } from '@/Redux/store';
import { getEquityAndLiability } from '../equityAndLiability/equityAndLiability.slice';
import { getProfitAnalysisData } from '../profitAnalysis/getProfitAnalysis.slice';

export enum RevenueAndExpensesIdentifiers {
    RevenueAndExpensesSave = 'SaveRevenueAndExpenses',
    AssetsSave = 'SaveAssets',
    EquitySave = 'SaveEquity',
}

type initialStateType = {
    isLoading: { [key in RevenueAndExpensesIdentifiers]?: boolean };
    isError: boolean;
    response: any | null;
    errorResponse: SerializedError | any | null;
};

type FetchResponse = {
    result: any;
    identifier: RevenueAndExpensesIdentifiers;
};

type FetchError = {
    error: SerializedError | any;
    identifier: RevenueAndExpensesIdentifiers;
};

type ThunkApiConfig = {
    rejectValue: FetchError;
    state: RootState;
};

const initialStateApi: initialStateType = {
    isLoading: {},
    isError: false,
    response: null,
    errorResponse: null,
};
// const dispatch = useDispatch<AppDispatch>();
export const saveRevenueAndExpenses = createAsyncThunk<
    FetchResponse,
    {
        data: any;
        identifier: RevenueAndExpensesIdentifiers;
        queryParams?: { [key: string]: any };
        year?: number;
    },
    ThunkApiConfig
>(
    'revenueAndExpenses',
    async (
        { data, identifier, queryParams = {}, year },
        { rejectWithValue, dispatch, getState }
    ) => {
        try {
            const state = getState();
            const sensitivityAnalysis =
                state?.powerGenerationData?.response?.data?.sensitivityAnalysis;
            const activeYear = state?.powerGenerationData?.response?.data?.year;
            const financialYear =
                state?.powerGenerationData?.response?.data?.financials?.financialYear;

            const url = addQueryParams(api.config.revenueAndExpenses(), queryParams);

            const response = await fetch(url, {
                ...api.http.patch(data),
            });

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            api.afterFetchHandlers.checkStatus(response);

            switch (identifier) {
                case RevenueAndExpensesIdentifiers.AssetsSave:
                    dispatch(getfinancialAssets());
                    dispatch(
                        getPowerGenerationData({
                            data: {
                                year: activeYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    break;
                case RevenueAndExpensesIdentifiers.EquitySave:
                    dispatch(getEquityAndLiability());
                    dispatch(
                        getPowerGenerationData({
                            data: {
                                year: activeYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    break;
                case RevenueAndExpensesIdentifiers.RevenueAndExpensesSave:
                    dispatch(
                        getPowerGenerationData({
                            data: {
                                year: activeYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    break;
                default:
                    break;
            }

            return { result, identifier };
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue({ error, identifier });
        }
    }
);

const revenueAndExpensesData = createSlice({
    name: 'revenueAndExpensesData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<initialStateType>) => {
        builder
            .addCase(saveRevenueAndExpenses.pending, (state, action) => {
                state.isLoading[action.meta.arg.identifier] = true;
            })
            .addCase(
                saveRevenueAndExpenses.fulfilled,
                (state, action: PayloadAction<FetchResponse>) => {
                    state.isLoading[action.payload.identifier] = false;
                    state.response = action.payload.result;
                }
            )
            .addCase(saveRevenueAndExpenses.rejected, (state, action) => {
                const { identifier } = action.meta.arg as {
                    identifier: RevenueAndExpensesIdentifiers;
                };
                state.isLoading[identifier] = false;
                state.errorResponse = action.payload?.error || action.error;
            });
    },
});
export default revenueAndExpensesData.reducer;
