import classes from './Layout.module.scss';
import Sidebar from './Sidebar/Sidebar';
import { NavigateProps, useNavigate, Outlet, useBlocker } from 'react-router-dom';
import Header from './Header/Header';
import AlertPopup from '@/components/commons/AlertPopup/AlertPopup';
import { useSelector } from 'react-redux';
import { moduleAccessResponse } from '@/Redux/slices/authentication/moduleAccessGet/moduleAccessGet.selector';
import { ROUTES } from '@/utils/constants/routes';
import { useEffect } from 'react';

function Layout() {
    const navigate=useNavigate()
    const moduleAccessData = useSelector(moduleAccessResponse)?.clemSubModules || [];
    const accessibleModules = (
        moduleAccessData.length > 0
            ? moduleMainOrder.filter((module) => {
                  const accessData = moduleAccessData.find(
                      (access: any) => access.name === module.name
                  );
                  return accessData?.access;
              })
            : moduleMainOrder
    )
        .sort((a, b) => a.order - b.order)
        .map((module, index) => ({
            ...module,
            order: index,
        }));

    const currentModule = accessibleModules.find((module) =>
        window.location.pathname.startsWith(module.to)
    );

    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        const nextModule = accessibleModules.find((module) =>
            nextLocation.pathname.startsWith(module.to)
        );

        if (!currentModule || !nextModule) {
            return false;
        }

        return nextModule.order > currentModule.order + 1;
    });

    const nextModule = accessibleModules.find(
        (module) => module.order === (currentModule?.order ?? -1) + 1
    );

    const navigateProps: NavigateProps = {
        to: nextModule?.to || '/',
        replace: false,
        state: { fromBlockedPage: true },
    };

    const loggedIn = localStorage.getItem('user_token');

    useEffect(() => {
        if (!loggedIn) navigate('/');
    }, [loggedIn]);

    return (
        <div className={classes['layout-container']}>
            {blocker.state === 'blocked' && nextModule && (
                <AlertPopup
                    blocker={blocker}
                    message={currentModule?.message}
                    buttonText={nextModule.continueButtonText || 'Continue'}
                    navigateProps={navigateProps}
                />
            )}

            <Sidebar />

            <div className={classes['active-component-container']}>
                <div className={classes['active-component-container-header']}>
                    <Header />
                </div>
                <div className={classes['active-component-container-content']}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Layout;

let moduleMainOrder = [
    {
        name: 'dataCollection',
        to: ROUTES.DATA_COLLECTION.INDEX,
        message:'',
        continueButtonText: 'Continue with Data Collection',
        order: 0,
    },
    {
        name: 'current-state',
        to: ROUTES.CURRENT_STATE,
        message:'',

        continueButtonText: 'Continue with Current State',
        order: 1,
    },
    {
        name: 'transition-plan',
        to: ROUTES.TRANSITION_PLAN,
        message:'',

        continueButtonText: 'Continue with Transition Plan',
        order: 2,
    },
    {
        name: 'economic-gap',
        to: ROUTES.ECONOMIC_GAP,
        message:'',
        continueButtonText: 'Continue with Economic Gap',
        order: 3,
    },
    {
        name: 'clean-energy-tech-mix',
        to: ROUTES.CLEAN_ENERGY_TECH_MIX,
        message:'',

        continueButtonText: 'Continue with Clean Energy Tech Mix',
        order: 4,
    },
    {
        name: 'transition-financing',
        to: ROUTES.TRANSITION_FINANCING.TRANSITION_FINANCE,
        message:'',

        continueButtonText: 'Continue with Transition Financing',
        order: 5,
    },
    {
        name: 'financials',
        to: ROUTES.FINANCIALS.TRANSITION_FINANCING,
        message:'',
        continueButtonText: 'Continue with Financials',
        order: 6,
    },
    {
        name: 'financial-summary',
        to: ROUTES.FINANCIAL_SUMMARY,
        message:'',
        continueButtonText: 'Continue with Financial Summary',
        order: 7,
    },
];
