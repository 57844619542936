import api from "@/api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

import { initialStateApi } from "@/Redux/utils";

type payloadType = {
  params?: Record<string, (string | any)>;
};

export const getFinalEnergyMixOptions: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getFinalEnergyMixOptions",
    async ({params}, { rejectWithValue }) => {
      try {
        
        const response = await fetch(
          api.config.finalEnergyMixOptions(params),
          api.http.get()
        );

        await api.afterFetchHandlers.checkStatus(response)

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        return result;

      } catch (error: any) {
        const result = await error.json();
            
        return rejectWithValue(result.message);
      }
    }
  );

const finalEnergyMixOptions = createSlice({
  name: "finalEnergyMixOptions",
  initialState: initialStateApi,
  reducers: {
    resetFinalReData: () => initialStateApi,
  },
  extraReducers(builder) {
    builder
      .addCase(getFinalEnergyMixOptions.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getFinalEnergyMixOptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getFinalEnergyMixOptions.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export const { resetFinalReData } = finalEnergyMixOptions.actions;

export default finalEnergyMixOptions.reducer;
