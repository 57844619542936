import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectionState {
    applyData: any;
}

const initialState: SelectionState = {
    applyData: {},
};

const applyDataSlice = createSlice({
    name: 'applyDataSlice',
    initialState,
    reducers: {
        setApplyData: (state, action: PayloadAction<any>) => {
            state.applyData = action.payload;
        },
    },
});

export const { setApplyData } = applyDataSlice.actions;
export default applyDataSlice.reducer;
