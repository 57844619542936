import api from "../../../api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../utils";

type payloadType = {
  params?: Record<string, (string | any)>;
};

export const getComparePowerCurve: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getComparePowerCurve",
    async ({params}, { rejectWithValue }) => {
      try {
        
        const response = await fetch(
          api.config.comparetPowerCurve(params),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const powerCurveComparisionData = createSlice({
  name: "powerCurveComparisionData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getComparePowerCurve.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getComparePowerCurve.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getComparePowerCurve.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default powerCurveComparisionData.reducer;
