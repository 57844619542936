// import library components
import api from "../../../api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../utils";

type payloadType = {
  apiEndpoint: string;
};

export const getCurrentSectionData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getCurrentSectionData",
    async ({apiEndpoint}, { rejectWithValue }) => {
      
      try {
        const response = await fetch(
          apiEndpoint,
          api.http.get()
        );
      
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const currentSectionData = createSlice({
  name: "currentSectionData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCurrentSectionData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getCurrentSectionData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getCurrentSectionData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default currentSectionData.reducer;
