// import library components
import api from "../../../api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../utils";

type payloadType = {
};

export const getInfo: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getInfo",
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.getInfo(),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const tabCompletionData = createSlice({
  name: "tabCompletionData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getInfo.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default tabCompletionData.reducer;
