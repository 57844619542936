const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://se-clem-dev.sustainabilityeconomics.com/api/v1';

const constructUrlWithParams = (url: string, params?: Record<string, string | any>) => {
    if (params && Object.keys(params).length) {
        return `${BASE_URL}${url}?${new URLSearchParams(params).toString()}`.trim();
    }
    return `${BASE_URL}${url}`.trim();
};

const constructUrlWithParams1 = (url: string, params?: Record<string, string | any>) => {
    if (params && Object.keys(params).length) {
        return `${process.env.REACT_APP_BASE_URL1}${url}?${new URLSearchParams(params).toString()}`.trim();
    }
    return `${process.env.REACT_APP_BASE_URL1}${url}`.trim();
};

const cfppApi = {
    cfppDetails: () => `${BASE_URL}/dataCollection/cfppDetails/details`, // GET
    cfppDetailsCapture: () => `${BASE_URL}/dataCollection/cfppDetails/capture`, // POST
    cfppTechnicals: () => `${BASE_URL}/dataCollection/cfppTechnicals/details`, // GET
    cfppTechnicalsCapture: () => `${BASE_URL}/dataCollection/cfppTechnicals/capture`, // POST
    cfppCoal: () => `${BASE_URL}/dataCollection/cfppCoal/details`, // GET
    cfppCoalCapture: () => `${BASE_URL}/dataCollection/cfppCoal/capture`, // POST
    cfppEconomics: () => `${BASE_URL}/dataCollection/cfppEconomics/details`, // GET
    cfppEconomicsCapture: () => `${BASE_URL}/dataCollection/cfppEconomics/capture`, // POST
    cfppFinancials: () => `${BASE_URL}/dataCollection/cfppFinancials/details`, // GET
    cfppFinancialsCapture: () => `${BASE_URL}/dataCollection/cfppFinancials/capture`, // POST
    cfppInvestors: () => `${BASE_URL}/dataCollection/cfppInvestors/details`, // GET
    cfppInvestorsCapture: () => `${BASE_URL}/dataCollection/cfppInvestors/capture`, // POST
    cfppTransition: () => `${BASE_URL}/dataCollection/transitionApproach/details`, // GET
    cfppTransitionCapture: () => `${BASE_URL}/dataCollection/transitionApproach/capture`, // POST
};

const fileUploadApi = {
    fileUploadPost: () => `${BASE_URL}/dataCollection/common/fileUpload`, // POST
    fileUploadDelete: (method: string, index?: number) =>
        `${BASE_URL}/dataCollection/common/removeFile?&method=${method}${index ? `&index=${index}` : ''}`, //DELETE
};

const currentStateApi = {
    currentStateOverview: () => `${BASE_URL}/currentState/overview`, // GET
    powerGeneration: () => `${BASE_URL}/currentState/powerGenerationAndEmissions`, // GET
    equityAndLiability: () =>
        `${BASE_URL}/currentState/finanical-equity-liability-equity-debt-analysis`,
    currentStateData: () => `${BASE_URL}/energyMix/currentState/details`, // GET
    profitAnalysis: () => `${BASE_URL}/currentState/finanical-profit-analysis`, // GET
    financialAssets: () => `${BASE_URL}/currentState/finanical-assets`, // GET
    saveSensitivityAnalysis: () => `${BASE_URL}/currentState/sensitivityAnalysisUpdate`, // GET
    revenueAndExpenses: () => `${BASE_URL}/currentState/entityUpdate`,
    resetSensitivityAnalysis: () => `${BASE_URL}/currentState/reset-current-gap-outcomes`,
    CreditRating: () => `${BASE_URL}/currentState/credit-rating`,
    assumptions: () => `${BASE_URL}/currentState/assumptions`,
};
const transitionPlanApi = {
    transitionPlanData: (method?: string) =>
        `${BASE_URL}/energyMix/transitionPlan/get-transition-plan-overview?method=${method}`,
    PostTransitionPlan: () => `${BASE_URL}/energyMix/transitionPlan/transition-plan-timeline`,
    ResetTransitionPlan: () => `${BASE_URL}/energyMix/reset/transition-plan-timeline`,
    economicGapData: () => `${BASE_URL}/currentState/economicGap/transitionApproachNpv`, // GET
    powerPlants: () => `${BASE_URL}/currentState/economicGap/power-plants-list`,
    patchEconomicGapData: () => `${BASE_URL}/currentState/economicGap/sensitivityAnalysisUpdate`, // GET
    ResetEconomicGapData: () => `${BASE_URL}/currentState/reset-economic-gap-outcomes`,
    addDataForTransitionPhase: () =>
        `${BASE_URL}/energyMix/transitionPlan/add-model-data-for-transition-phase`, // POST
    getPolygonData: (params: Record<string, string | number> = {}) =>
        constructUrlWithParams('/transitionPlan/get-nearby-polygons', params), // GET
    addPolygonData: () => `${BASE_URL}/transitionPlan/add-polygon-data`, // POST
    comparetPowerCurve: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams('/transitionPlan/compare-specs', params), // GET

    reFinancialsData: () => `${BASE_URL}/financials/re-independent`, // GET
};
const login = {
    postLogin: () =>
        `https://se-marketplace-qa.sustainabilityeconomics.com/se-marketplace/v1/auth/login`,
    postOtpForgotPassword: () =>
        `https://se-marketplace-qa.sustainabilityeconomics.com/se-marketplace/v1/auth/forgotPassword`,
    postConfirmPasswordReset: () =>
        `https://se-marketplace-qa.sustainabilityeconomics.com/se-marketplace/v1/auth/confirmPasswordReset`,
    getmoduleAccess: () =>
        `https://se-marketplace-qa.sustainabilityeconomics.com/se-marketplace/v1/resources/moduleAccess?module=Clem`,
};

const financials = {
    getTimelineChartData: () => `${BASE_URL}/financials/timeline`,
    getPowerPurchaseAgreementData: () => `${BASE_URL}/financials/re-ppa-data`,
    getFinancialEstimateData: () => `${BASE_URL}/financials/get-revenue-expenses-with-tariff`,
    getReInvestorDetailsData: (type: string) =>
        `${BASE_URL}/financials/reBuilding${type ? `?type=${type}` : ''}`,
    getPowerGenerationData: () => `${BASE_URL}/financials/powerGenerationAndEmissions`,
    getEmissionAvoidedData: () => `${BASE_URL}/financials/emission-avoided-data`,
    getCashflowData: () => `${BASE_URL}/financials/get-cashflow-based-data`,
    getAdditionalityData: () => `${BASE_URL}/financials/additionalityData`,
    getCharacteristicsOfCfppData: () => `${BASE_URL}/financials/additionalityOverview`,
    getTransitionCreditsTimelineData: () => `${BASE_URL}/financials/transition-credits-timeline`,
    getTransitionCreditData: () => `${BASE_URL}/financials/transitionCredits`,
    getTransitionCreditCashflowData: () => `${BASE_URL}/financials/transitionCreditsCashFlow`,
    postWaccData: () => `${BASE_URL}/financials/transitionCreditsCashFlow`, //need to change the api
    updateSaData: (type: string) =>
        // `${BASE_URL}/financials/update-sensitivity-analysis?type=${type}`,
        `${BASE_URL}/financials/update-sensitivity-analysis?type=${type}`,
    financialsReset: (type: string) =>
        `${BASE_URL}/financials/reset-sensitivity-analysis?type=${type}`,
    financialsAssumptions: () => `${BASE_URL}/financials/assumptions`,

};

const cleanEnergyTechMixApi = {
    cetmTimelineAndPhases: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams(
            '/energyMix/transitionPlan/get-clean-energy-timeline-card-overview',
            params
        ), // GET
    // cleanEnergyTechMixCardInfoAndConstraints: (params: Record<string, string | any> = {}) =>
    //     constructUrlWithParams('/transitionPlan/get-card-info-by-phase', params), // GET
    cleanEnergyTechMixCardInfoAndConstraints: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams1('/transitionPlan/get-constraints-based-card-info', params), // GET
    constraintsDropdown: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams('/energyMix/transitionPlan/constraints-dropdown', params), // GET
    finalEnergyMixOptions: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams1('/transitionPlan/get-constraints-based-card-info', params), // GET
    postCleanEnergyTechMixData: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams('/energyMix/transitionPlan/clean-energy-mix-data', params), // POST
    postLandDetails: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams('/energyMix/transitionPlan/update-land-details', params), // POST
    gridConnectivity: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams('/energyMix/transitionPlan/get-grid-connectivity-data', params), // GET
    modalReferences: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams('/energyMix/transitionPlan/compare-model-references', params), // GET
    energyMixData: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams1('/transitionPlan/plan-details-v3', params), // GET
    getGraphDataForDates: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams1('/transitionPlan/get-graph-data-for-dates', params), // GET
};

const utilities = {
    getDropdowns: () => `${BASE_URL}/dataCollection/common/dropdowns`,
    getInfo: () => `${BASE_URL}/dataCollection/common/getInfo`,
    equipmentDropdown: () => `${BASE_URL}/energyMix/transitionPlan/dropdowns`,
};

const transitionFinancingApi = {
    getTransitionFinanceData: () => `${BASE_URL}/transitionFinance/transition-finance-static-data`,

    getTransitionFinanceFrameworkData: (
        type: string,
        senior_debt: number,
        junior_debt: number,
        equity_value: number
    ) =>
        `${BASE_URL}/transitionFinance/transition-finance-framework?type=${type}${senior_debt ? `&senior_debt=${senior_debt}` : ''}${junior_debt ? `&junior_debt=${junior_debt}` : ''}${equity_value ? `&equity_value=${equity_value}` : ''}`,

    saveCapitalStructure: (type: string) =>
        `${BASE_URL}/transitionFinance/transition-finance-framework?type=${type}`,
    postTfSave: (params: Record<string, string | any> = {}) =>
        constructUrlWithParams('/transitionFinance/transition-finance-static-data', params),
    patchTfApply: () => `${BASE_URL}/transitionFinance/transition-updated`,
};

const financialSummary = {
    getFinancialSummary: () => `${BASE_URL}/financials/fianancialSummery`,
};

const path = {
    ...cfppApi,
    fileUploadApi,
    ...currentStateApi,
    ...transitionPlanApi,
    ...transitionFinancingApi,
    ...financials,
    ...financialSummary,
    ...cleanEnergyTechMixApi,
    ...utilities,
    ...financials,
    ...login,
};

export default path;
