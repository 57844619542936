import React, { forwardRef } from 'react';
import styles from './ProjectDocument.module.scss'
import { ReactComponent as NotFoundImg } from '@/assets/icons/notFoundImg.svg';

const ProjectDocument = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div ref={ref} className={styles['project-document-container']}>
            <NotFoundImg />
        </div>
    );
});

export default ProjectDocument;
