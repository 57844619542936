import api from "../../../api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../utils";

type payloadType = {
  params?: Record<string, (string | any)>;
};

export const getEnergyMixData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getEnergyMixData",
    async ({params}, { rejectWithValue }) => {
      try {
        
        const response = await fetch(
          api.config.energyMixData(params),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const energyMixData = createSlice({
  name: "energyMixData",
  initialState: initialStateApi,
  reducers: {
    resetEnergyMixData: (state) => {
      state = initialStateApi
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEnergyMixData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getEnergyMixData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getEnergyMixData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export const { resetEnergyMixData } = energyMixData.actions;

export default energyMixData.reducer;
