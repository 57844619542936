import transitionFinancingAndCreditsSubTabKeys from "./transitionFinancingAndCreditsSubTabKeys";

const { additionality, transitionCreditCashflow, projectDocument, justTransition, sdgGoals, reporting } = transitionFinancingAndCreditsSubTabKeys;


type FinancingCreditsProps = {
    key: string;
    title: string;
};

const transitionFinancingAndCreditsSubTabs: FinancingCreditsProps[] = [
    { key: additionality, title: 'Additionality' },
    { key: transitionCreditCashflow, title: 'Transition Credit Cashflow' },
    { key: projectDocument, title: 'Project Document' },
    { key: justTransition, title: 'Just Transition' },
    { key: sdgGoals, title: 'SDG Goals' },
    { key: reporting, title: 'Reporting' },
];

export default transitionFinancingAndCreditsSubTabs;
