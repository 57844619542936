import React from 'react';
import sideBarKeys from './sideBarKeys';
import DataCollection from '@/assets/icons/dataCollectionIcon';
import dataCollectionSubTabs from './dataCollectionSubTabs';
import CurrentState from '@/assets/icons/currentStateIcon';
import TransitionPlan from '../../assets/icons/transitionPlanIcon';
import CleanEneryTechMixIcon from '@/assets/icons/cleanEnergyTechMix';
import TransitionFinancingIcon from '@/assets/icons/transitionFinancing';
import FinancialSummaryIcon from '@/assets/icons/financialSummary';
import EconomicGapIcon from '@/assets/icons/economicGap';
import FinancialsWithTFIcon from '@/assets/icons/withTf';
import FinancialsWithTFAndTcIcon from '@/assets/icons/withTfAndTc';
import transitionFinancingAndCreditsSubTabs from './transitionFinancingAndCreditsSubTabs';
import TransitionFinanceIcon from '@/assets/icons/TransitionFinanceIcon';
import FutureStateIcon from '@/assets/icons/FutureStateIcon';

const { dataCollection, currentState, transitionPlan, 
    economicGap, cleanEnergyTechMix, transitionFinancing, financials, financialSummary } = sideBarKeys;

type SidebarSubTab = {
    key: string;
    iconComponent?: React.FC;
    title: string;
    list?: {
        key: string,
        title: string
    }[];
};

type SidebarTab = {
    key: string;
    iconComponent: React.FC;
    title: string;
    subTabs?: {
        isPrimary: boolean;
        list: SidebarSubTab[];
    }
};

const sidebarContentCompMapping: SidebarTab[] = [
    {
        key: dataCollection,
        iconComponent: DataCollection,
        title: 'Data Collection',
        subTabs: {
            isPrimary: false,
            list: dataCollectionSubTabs,
        },
    },
    {
        key: currentState,
        iconComponent: CurrentState,
        title: 'Current State',
    },
    {
        key: transitionPlan,
        iconComponent: TransitionPlan,
        title: 'Transition Plan',
    },
    {
        key: economicGap,
        iconComponent: EconomicGapIcon,
        title: 'Economic Gap',
    },
    {
        key: cleanEnergyTechMix,
        iconComponent: CleanEneryTechMixIcon,
        title: 'Clean Energy Technology Mix',
    },
    {
        key: transitionFinancing,
        iconComponent: TransitionFinancingIcon,
        title: 'Transition Financing',
        subTabs: {
            isPrimary: true,
            list: [
                {
                    key: 'transition-finance',
                    iconComponent: TransitionFinanceIcon,
                    title: 'Transition Finance',
                },
                {
                    key: 'transition-financing-future-state',
                    iconComponent: FutureStateIcon,
                    title: 'Transition Finance Framework',
                }
            ],
        },
    },
    {
        key: financials,
        iconComponent: DataCollection,
        title: 'Financials',
        subTabs: {
            isPrimary: true,
            list: [
                {
                    key: 'transtion-financing',
                    iconComponent: FinancialsWithTFIcon,
                    title: 'With Transition Financing',
                },
                {
                    key: 'transtion-financing-transtion-credits',
                    iconComponent: FinancialsWithTFAndTcIcon,
                    title: 'With Transition Financing and Transition Credits',
                    list: transitionFinancingAndCreditsSubTabs,
                },
                // {
                //     key: 'test',
                //     iconComponent: FinancialsWithTFIcon,
                //     title: 'Test',
                // },
            ],
        },
    },
    {
        key: financialSummary,
        iconComponent: FinancialSummaryIcon,
        title: 'Financial Summary',
    },
];

export default sidebarContentCompMapping;
