import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import api from '../../../../api';
import { RootState } from '@/Redux/store';
import { toast } from 'react-toastify';

interface AssumptionsState {
    loading: boolean;
    data: any | null;
    error: any | null;
}

interface FetchResponse {
    data: any;
}

interface FetchError {
    error: any;
}

interface ThunkApiConfig {
    rejectValue: FetchError;
    state: RootState;
}

const initialState: AssumptionsState = {
    loading: false,
    data: null,
    error: null,
};

export const fetchAssumptions = createAsyncThunk<
    FetchResponse,
    void,
    ThunkApiConfig
>('assumptions/fetch', async (_, { rejectWithValue }) => {
    try {
        const url = api.config.assumptions();
        const response = await fetch(url,api.http.get());
        const data = await api.afterFetchHandlers.parseContent(response, api.http.get());
        api.afterFetchHandlers.checkStatus(response);
        return { data };
    } catch (error: any) {
        return rejectWithValue({ error });
    }
});

const assumptionsSlice = createSlice({
    name: 'assumptions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAssumptions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAssumptions.fulfilled, (state, action: PayloadAction<FetchResponse>) => {
                state.loading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchAssumptions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.error || action.error;
            });
    },
});

export default assumptionsSlice.reducer;
