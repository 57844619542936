import api from '../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '../../utils';
import { toast } from 'react-toastify';

type payloadType = {
    apiEndPoint: string;
    payload: Record<string, any>;
};

export const addCurrentSectionDataAction: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'addCurrentSectionDataAction',
    async ({ apiEndPoint, payload }, { rejectWithValue }) => {
        try {
            const filesKey = ['images', 'csaLink', 'ppa'];
            let response;
            if (filesKey.some((key) => key in payload)) {
                let formData = new FormData();

                for (const [key, value] of Object.entries(payload)) {
                    if (filesKey.includes(key)) {
                        if (Array.isArray(value)) {
                            // GET Files as separate
                            const binaryFiles = value.filter((file: any) => file instanceof File);
                            const otherFiles = value.filter(
                                (file: any) => !(file instanceof File) && !!file
                            );

                            for (let i = 0; i < binaryFiles.length; i++) {
                                formData.append(key, binaryFiles[i]);
                            }

                            // Sending other files even if its empty to override the previous files
                            formData.append(key, JSON.stringify(otherFiles));
                        } else if (value) {
                            formData.append(key, value);
                        }
                    } else if (Array.isArray(value) || typeof value === 'object') {
                        formData.append(key, JSON.stringify(value));
                    } else if (value === 0 || !!value) {
                        formData.append(key, value as any);
                    }
                }
                response = await fetch(apiEndPoint, api.http.postForm(formData));
            } else {
                response = await fetch(apiEndPoint, api.http.post(payload));
            }

            const result = await api.afterFetchHandlers.checkStatus(response);

            return result;
        } catch (error: any) {
            if (!error.ok) {
                const result =
                    (await api.afterFetchHandlers.parseContent(error, api.http.post(payload))) ||
                    {};
                toast.error(result.message || 'Failed to save data!');
            }
            rejectWithValue(error);
        }
    }
);

const addCurrentSectionData = createSlice({
    name: 'addCurrentSectionData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(addCurrentSectionDataAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(addCurrentSectionDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(addCurrentSectionDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
                state.isError = true;
            });
    },
});

export default addCurrentSectionData.reducer;
