// import library components
import api from '../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

//  initial state
import { initialStateApi } from '../../utils';

type payloadType = {
    apiEndpoint: string;
};

export const getCurrentStateData: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'getCurrentStateData',
    async ({ apiEndpoint }, { rejectWithValue }) => {
        try {
            const response = await fetch(apiEndpoint, api.http.get());

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            rejectWithValue(error);
        }
    }
);

const currentStateData = createSlice({
    name: 'currentStateData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getCurrentStateData.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getCurrentStateData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getCurrentStateData.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default currentStateData.reducer;
