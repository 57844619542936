const sideBarKeys = {
    dataCollection: 'dataCollection',
    currentState: 'current-state',
    transitionPlan: 'transition-plan',
    economicGap: 'economic-gap',
    cleanEnergyTechMix: 'clean-energy-tech-mix',
    transitionFinancing: 'transition-financing',
    financials: 'financials',
    transitionfinancingtranstioncredits:'transtion-financing-transtion-credits',
    financialSummary: 'financial-summary',
    financialSummaryFutureState:'transition-financing-future-state'
};

export default sideBarKeys;
