import api from '@/api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '@/Redux/utils';

type payloadType = {
    payload: any;
};

type paramsType = {
    method: string;
    index?: number;
};

export const fileUploadPost: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'fileUploadPost',
    async ({ payload }, { fulfillWithValue, rejectWithValue }) => {
        const response = await fetch(
            api.config.fileUploadApi.fileUploadPost(),
            api.http.postForm(payload)
        );
        const result = await api.afterFetchHandlers.parseContent(response, api.http.post(payload));

        if (result.error) {
            return rejectWithValue(result.error);
        }
        if (!response.ok) {
            return rejectWithValue(`Api Failed with ${result.message}`);
        }
        fulfillWithValue(result);
    }
);

export const fileUploadDelete: AsyncThunk<any, paramsType, {}> = createAsyncThunk(
    'fileUploadDelete',
    async ({ method, index }, { fulfillWithValue, rejectWithValue }) => {
        const response = await fetch(
            api.config.fileUploadApi.fileUploadDelete(method,index),
            api.http.deleteParams()
        );
        const result = await api.afterFetchHandlers.parseContent(response, api.http.deleteParams());

        if (result.error) {
            return rejectWithValue(result.error);
        }
        if (!response.ok) {
            return rejectWithValue(`Api Failed with ${result.message}`);
        }
        fulfillWithValue(result);
    }
);

const fileUploadSlice = createSlice({
    name: 'Stakeholders',
    initialState: initialStateApi,
    reducers: {},
    // extraReducers(builder) {
    //     builder
    //         .addCase(getStakeholders.pending, (state, _) => {
    //             state.isLoading = true;
    //         })
    //         .addCase(getStakeholders.fulfilled, (state, action) => {
    //             state.isLoading = false;
    //             state.response = action.payload;
    //         })
    //         .addCase(getStakeholders.rejected, (state, action) => {
    //             state.isLoading = false;
    //             state.errorResponse = action.error;
    //         });
    // },
});
export default fileUploadSlice.reducer;
