import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';
import { initialStateApi } from '@/Redux/utils';

type powerGenerationType = {
    payload?: any;
};

export const getPowerGenerationDataAction = createAsyncThunk(
    'getPowerGenerationDataAction',
    async (params: powerGenerationType, { rejectWithValue }) => {
        try {
            const { payload } = params;
            const url = api.config?.getPowerGenerationData();
            const response = await fetch(url, api.http.post(payload));
            const result = await api?.afterFetchHandlers?.parseContent(
                response,
                api.http.post(payload)
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const getPowerGenerationDataReducer = createSlice({
    name: 'getPowerGenerationDataAction',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPowerGenerationDataAction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPowerGenerationDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getPowerGenerationDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default getPowerGenerationDataReducer.reducer;
