import api from '../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '../../utils';

type payloadType = {
    apiEndPoint: string;
    payload: Record<string, any>;
};

export const addLandDetailsAction: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'addLandDetailsAction',
    async ({ apiEndPoint, payload }, { rejectWithValue }) => {
        try {
            const response = await fetch(apiEndPoint, api.http.post(payload));

            const result = await api.afterFetchHandlers.checkStatus(response)
            return result;

        } catch (error: any) {
            const result = await error.json();
            
            return rejectWithValue(result.message || 'Failed to save data');
        }
    }
);

const addLandDetails = createSlice({
    name: 'addLandDetails',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(addLandDetailsAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(addLandDetailsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(addLandDetailsAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
                state.isError = true;
            });
    },
});

export default addLandDetails.reducer;
