import { createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';

type forgotPasswordPayload = {
    email: string;
};

export const forgotPwdDataAction: AsyncThunk<any, forgotPasswordPayload, {}> = createAsyncThunk(
    'forgotPwdDataAction',
    async (payload: forgotPasswordPayload, { rejectWithValue }) => {
        try {
            const url = api.config?.postOtpForgotPassword();
            const response = await fetch(url, api.http.post(payload)); // Changed to `api.http.post` for POST request
            const result = await api?.afterFetchHandlers?.parseContent(
                response,
                api.http.post(payload)
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);
