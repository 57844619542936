import {
    createSlice,
    createAsyncThunk,
    SerializedError,
    PayloadAction,
    ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import api from '../../../../api';
import { addQueryParams } from '@/api/helper';
import { RootState } from '@/Redux/store';
import { toast } from 'react-toastify';
import {
    getPowerGenerationData,
    APILoadingIdentifiers,
} from '../powerGeneration/powerGeneration.slice';
import { getEquityAndLiability } from '../equityAndLiability/equityAndLiability.slice';
import { getfinancialAssets } from '../financialAssets/financialAssets.slice';
import getProfitAnalysisSlice, {
    getProfitAnalysisData,
} from '../profitAnalysis/getProfitAnalysis.slice';
import { message } from 'antd';
 
export enum ResetIdentifiers {
    RESET_GROSS_POWER = 'resetGrossPower',
    RESET_AUX = 'resetAux',
    RESET_EMISSION = 'resetEmission',
    RESET_FINANCIAL = 'resetFinancial',
    RESET_CASHFLOWS = 'resetCashflows',
    RESET_WACC = 'resetWacc',
    RESET_CURRENTSTATE = 'resetCurrentState',
    RESET_EQUITY_AND_LIABILITY = 'resetEquityAndLiabilty',
    RESET_EQUITY_AND_LIABILITY_OTHERS = 'resetEquityAndLiabiltyOthers',
    RESET_ASSETS = 'resetAssets',
    RESET_REVENUE_TABLE = 'resetRevenueTable',
    RESET_EXPENSES_TABLE = 'resetExpensesTable',
}
 
interface InitialStateType {
    isLoading: { [key in ResetIdentifiers]?: boolean };
    isError: boolean;
    response: any | null;
    errorResponse: SerializedError | any | null;
}
 
interface FetchResponse {
    result: any;
    identifier: ResetIdentifiers;
}
 
interface FetchError {
    error: SerializedError | any;
    identifier: ResetIdentifiers;
}
 
interface ThunkApiConfig {
    rejectValue: FetchError;
    state: RootState;
}
 
interface ResetParams {
    data: any;
    identifier: ResetIdentifiers;
    queryParams?: { [key: string]: any };
    sensitivityAnalysis: any;
}
 
const initialStateApi: InitialStateType = {
    isLoading: {},
    isError: false,
    response: null,
    errorResponse: null,
};
 
export const resetSensitivityAnalysis = createAsyncThunk<
    FetchResponse,
    ResetParams,
    ThunkApiConfig
>(
    'resetSensitivityAnalysis',
    async (
        { data, identifier, queryParams = {}, sensitivityAnalysis },
        { rejectWithValue, dispatch, getState }
    ) => {
        try {
            const url = addQueryParams(api.config.resetSensitivityAnalysis(), queryParams || {});
            const response = await fetch(url, {
                ...api.http.post(data),
            });
            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            api.afterFetchHandlers.checkStatus(response);
 
            const state = getState();
            const financialYear =
                state?.powerGenerationData?.response?.data?.financials?.financialYear;
            const powerGenYear = state?.powerGenerationData?.response?.data?.year;
            switch (identifier) {
                case ResetIdentifiers.RESET_EQUITY_AND_LIABILITY:
                    await dispatch(getEquityAndLiability());
                    await dispatch(
                        getPowerGenerationData({
                            data: {
                                year: powerGenYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    break;
                case ResetIdentifiers.RESET_EQUITY_AND_LIABILITY_OTHERS:
                    await dispatch(getEquityAndLiability());
                    await dispatch(
                        getPowerGenerationData({
                            data: {
                                year: powerGenYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    break;
                case ResetIdentifiers.RESET_EQUITY_AND_LIABILITY_OTHERS:
                    await dispatch(getEquityAndLiability());
                    break;
                case ResetIdentifiers.RESET_ASSETS:
                    await dispatch(getfinancialAssets());
                    await dispatch(
                        getPowerGenerationData({
                            data: {
                                year: powerGenYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    break;
                case ResetIdentifiers.RESET_FINANCIAL:
                case ResetIdentifiers.RESET_EXPENSES_TABLE:
                case ResetIdentifiers.RESET_REVENUE_TABLE:
                    await dispatch(
                        getPowerGenerationData({
                            data: {
                                year: powerGenYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    await dispatch(getProfitAnalysisData());
                    break;
                default:
                    await dispatch(
                        getPowerGenerationData({
                            data: {
                                year: powerGenYear,
                                financialYear,
                                senstivityAnalysis: { ...sensitivityAnalysis },
                            },
                            identifier: APILoadingIdentifiers.INITIAL_GET,
                        })
                    );
                    break;
            }
 
            return { result, identifier };
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue({ error, identifier });
        }
    }
);
 
const resetSensitivityAnalysisState = createSlice({
    name: 'resetSensitivityAnalysisState',
    initialState: initialStateApi,
    reducers: {
        resetState: () => initialStateApi,
    },
    extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
        builder
            .addCase(resetSensitivityAnalysis.pending, (state, action) => {
                state.isLoading[action.meta.arg.identifier] = true;
            })
            .addCase(
                resetSensitivityAnalysis.fulfilled,
                (state, action: PayloadAction<FetchResponse>) => {
                    state.isLoading[action.payload.identifier] = false;
                    state.response = action.payload.result;
                    toast.success(`Reset successful`);
                }
            )
            .addCase(resetSensitivityAnalysis.rejected, (state, action) => {
                const { identifier } = action.meta.arg as {
                    identifier: ResetIdentifiers;
                };
                state.isLoading[identifier] = false;
                state.errorResponse = action.payload?.error || action.error;
                toast.error(`Something went wrong!`);
            });
    },
});
 
export const { resetState } = resetSensitivityAnalysisState.actions;
export default resetSensitivityAnalysisState.reducer;