import React, { useEffect, useState } from 'react';
import SidebarSubTabs from '../SidebarSubTabs/SidebarSubTabs';
import './Sidebar.scss';
import { companyLogo } from '../../../assets/images/images';
import sidebarContentCompMapping from '../../../utils/data/sidebarContentMapping';
import { Fragment } from 'react/jsx-runtime';
import { useNavigate, useLocation } from 'react-router-dom';
import { getModuleAccess } from '@/Redux/slices/authentication/moduleAccessGet/moduleAccessGet.slice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/Redux/store';
import { moduleAccessResponse } from '@/Redux/slices/authentication/moduleAccessGet/moduleAccessGet.selector';
import Loader from '@/components/commons/Loader/Loader';

interface Props {
    color?: React.CSSProperties['color'];
    height?: number;
    width?: number;
}

type SidebarSubTab = {
    key: string;
    iconComponent?: React.FC<Props>;
    title: string;
    list?: {
        key: string;
        title: string;
    }[];
};

type SidebarTab = {
    key: string;
    iconComponent: React.FC<Props>;
    title: string;
    subTabs?: {
        isPrimary: boolean;
        list: SidebarSubTab[];
    };
};
type SubModule = {
    access: boolean;
    name: string;
};

const Sidebar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const moduleAccessSelector = useSelector(moduleAccessResponse);
    const [loading, setLoading] = useState(true);
    const [permissions, setClemSubModules] = useState<SubModule[]>([]);
    const [expandedTab, setExpandedTab] = useState<string | null>(null);

    const navigate = useNavigate();
    const location = useLocation();

    const pathSegments = location.pathname.split('/').filter(Boolean);

    const handleClick = (path: string, pathToAppend: string | undefined, key: string) => {
        key !== 'economic-gap' && window.scrollTo({ top: 0, behavior: 'auto' });
        navigate(`${path}${pathToAppend ? `/${pathToAppend}` : ''}`);
        setExpandedTab((prev) => (prev === key ? null : key));
    };

    const permissionMap = new Map(permissions.map(({ name, access }) => [name, access]));
    const hasAccess = (key: string) => {
        return permissionMap.get(key) !== false;
    };

    useEffect(() => {
        dispatch(getModuleAccess({}))
            .then((response) => {
                if (response.meta.requestStatus === 'fulfilled') {
                    if (response.payload.clemSubModules) {
                        setClemSubModules(response.payload.clemSubModules);
                    }
                    setLoading(false);
                } else {
                    setLoading(true);
                }
            })
            .catch((error) => {
                setLoading(true);
            });
    }, [dispatch]);

    if (loading) {
        return (
            <div className="sidebar-container">
                <Loader imgStyles={{ width: '20%' }} />
            </div>
        );
    }

    return (
        <div className="sidebar-container">
            <div className="sidebar-inner-container">
                <div className="company-logo-wrap">
                    <img src={companyLogo} alt="logo" className="company-logo" />
                </div>
                <div className="divider" />

                {sidebarContentCompMapping.map(
                    ({ key: mainTabKey, iconComponent: Icon, title, subTabs }: SidebarTab) => {
                        const { isPrimary, list } = subTabs || {};
                        const tabAccess = mainTabKey === pathSegments[0] || hasAccess(mainTabKey);

                        const iconProps = mainTabKey === pathSegments[0] ? { color: '#fff' } : {};

                        return (
                            <Fragment key={mainTabKey}>
                                <div
                                    onClick={() => {
                                        if (tabAccess) {
                                            const listFirstKey =
                                                list && list.length > 0 ? list[0].key : undefined;
                                            handleClick(`/${mainTabKey}`, listFirstKey, mainTabKey);
                                        }
                                    }}
                                    className={`sidebar-item${pathSegments[0] === mainTabKey ? ' active' : ''}${!tabAccess ? ' disabled' : ''}`}
                                >
                                    <Icon {...iconProps} />

                                    <span className="sidebar-item-title">{title}</span>
                                </div>

                                {mainTabKey === pathSegments[0] &&
                                isPrimary &&
                                list &&
                                list.length > 0
                                    ? list.map(({ key, title, list, iconComponent: Icon }) => {
                                          const subTabAccess =
                                              pathSegments[1] === key || hasAccess(key);
                                          const subTabIconProps =
                                              pathSegments[1] === key ? { color: '#0171D6' } : {};

                                          return (
                                              <Fragment key={key}>
                                                  <div
                                                      onClick={() => {
                                                          if (subTabAccess) {
                                                              const listFirstKey =
                                                                  list && list.length > 0
                                                                      ? list[0].key
                                                                      : undefined;

                                                              handleClick(
                                                                  `/${mainTabKey}/${key}`,
                                                                  listFirstKey,
                                                                  key
                                                              );
                                                          }
                                                      }}
                                                      className={`sidebar-sub-item${pathSegments[1] === key ? ' active' : ''}${!subTabAccess ? ' disabled' : ''}`}
                                                  >
                                                      {Icon ? <Icon {...subTabIconProps} /> : null}

                                                      <span className="sidebar-item-title">
                                                          {title}
                                                      </span>
                                                  </div>

                                                  {list && list.length > 0 ? (
                                                      <SidebarSubTabs
                                                          path={`/${mainTabKey}/${key}`}
                                                          activeSubTab={pathSegments[2]}
                                                          subTabs={list}
                                                          isExpanded={expandedTab === key}
                                                      />
                                                  ) : null}
                                              </Fragment>
                                          );
                                      })
                                    : null}

                                {!isPrimary && list && list.length > 0 ? (
                                    <SidebarSubTabs
                                        path={`/${mainTabKey}`}
                                        activeSubTab={pathSegments[1]}
                                        subTabs={list}
                                        isExpanded={expandedTab === mainTabKey}
                                    />
                                ) : null}
                            </Fragment>
                        );
                    }
                )}
            </div>
        </div>
    );
};

export default Sidebar;
