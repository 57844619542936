import React from 'react';

interface Props {
    color?: string;
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
    onClick?:any
}

const CrossIcon: React.FC<Props> = ({ color = '#4D4D4D', height = 18, width = 18 ,onClick}) => {
    return (
        <svg
            width={width}
            height={height}
            onClick={onClick}
            viewBox="0 0 18 18"
            cursor={"pointer"}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 1L1 17"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1 1L17 17"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default CrossIcon;
