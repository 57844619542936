import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';
import { AppDispatch } from '@/Redux/store';
import { useDispatch } from 'react-redux';
import { loginDataAction } from '@/Redux/slices/authentication/login/loginPost.slice';
import loginImg from '@/assets/icons/login/LoginImg.svg';
import { ReactComponent as ClemLogo } from '@/assets/icons/login/clemLogo.svg';
import { ReactComponent as SustainabilityLogo } from '@/assets/icons/login/sustainabilityLogo.svg';
import { toast } from 'react-toastify';
import { getModuleAccess } from '@/Redux/slices/authentication/moduleAccessGet/moduleAccessGet.slice';
import Spinner from '../Spinner/Spinner';
import { ReactComponent as ShowPasswordIcon } from '@/assets/icons/login/showPassword.svg';
import { ReactComponent as HidePasswordIcon } from '@/assets/icons/login/hidePassword.svg';

type SubModule = {
    access: boolean;
    name: string;
};

const Login: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [permissions, setClemSubModules] = useState<SubModule[]>([]);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const hasDisplayedError = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingModules, setIsFetchingModules] = useState(false);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const data = localStorage.getItem('authentication');

    useEffect(() => {
        if (data === 'no')
            toast.error('Your session has expired. Please log in again to continue.');
        localStorage.removeItem('authentication');
    }, [data]);

    useEffect(() => {
        localStorage.removeItem('user_token');
    }, []);

    const validateEmail = (email: string) => {
        if (email.trim() === '') {
            setEmailError('Email cannot be empty');
            return false;
        }
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setEmailError(isValid ? '' : 'Invalid email format');
        return isValid;
    };

    const validatePassword = (password: string) => {
        const isValid = password.length >= 8;
        setPasswordError(isValid ? '' : 'Password must be at least 8 characters');
        return isValid;
    };

    const handleLogin = async () => {
        const emailValid = validateEmail(email);
        const passwordValid = validatePassword(password);

        if (emailValid && passwordValid) {
            const payload = { email, password };
            setIsLoading(true);

            try {
                const resultAction = await dispatch(loginDataAction(payload));
                if (loginDataAction.rejected.match(resultAction)) {
                    toast.error('Login failed. Please try again.');
                } else {
                    const accessToken = resultAction.payload?.result?.data?.accessToken;

                    if (accessToken) {
                        localStorage.setItem('user_token', accessToken);

                        setIsFetchingModules(true);
                        const moduleAccessResponse = await dispatch(getModuleAccess({}));

                        if (getModuleAccess.fulfilled.match(moduleAccessResponse)) {
                            const { clemSubModules } = moduleAccessResponse.payload;
                            if (clemSubModules) {
                                setClemSubModules(clemSubModules);

                                const hasAccessibleModule = clemSubModules.some(
                                    (module: SubModule) => module.access
                                );

                                if (hasAccessibleModule) {
                                    const accessibleModule = clemSubModules.find(
                                        (module: SubModule) => module.access
                                    );
                                    if (accessibleModule) {
                                        navigate(`/${accessibleModule.name}`);
                                    }
                                } else {
                                    toast.error('You do not have access to any module.');
                                }
                            }
                        } else {
                            toast.error('Failed to fetch module access.');
                        }
                    } else {
                        toast.error('Failed to retrieve access token.');
                    }
                }
            } catch (error: any) {
                toast.error('An unexpected error occurred. Please try again.');
            } finally {
                setIsLoading(false);
                setIsFetchingModules(false);
            }
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (event.target === emailInputRef.current) {
                passwordInputRef.current?.focus();
            } else if (event.target === passwordInputRef.current) {
                handleLogin();
            }
        }
    };

    useEffect(() => {
        const emailValid = validateEmail(email);
        const passwordValid = validatePassword(password);
        setIsFormValid(emailValid && passwordValid);
        hasDisplayedError.current = false;
    }, [email, password]);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev);
    };

    return (
        <div className={styles['login-container']}>
            <div className={styles['image-section']}>
                <img className={styles['login-img']} alt="login img" src={loginImg} />
                <SustainabilityLogo className={styles['sustainability-logo']} />
            </div>
            <div className={styles['form-section']}>
                <ClemLogo />
                <div className={styles['heading']}>
                    <div>Welcome to</div>
                    <div>Clean Energy Mechanism (CLEM)</div>
                </div>
                <div className={styles['divider']} />
                <div className={styles['input-container']}>
                    <label htmlFor="email" className={styles['input-label']}>
                        Email
                    </label>
                    <input
                        id="email"
                        type="email"
                        placeholder="abc@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={() => setEmailTouched(true)}
                        onKeyDown={handleKeyDown}
                        ref={emailInputRef}
                        className={styles['input-field']}
                    />
                    {emailTouched && emailError && (
                        <div className={styles['error-message']}>{emailError}</div>
                    )}
                </div>
                <div className={styles['input-container']}>
                    <label htmlFor="password" className={styles['input-label']}>
                        Password
                    </label>
                    <div className={styles['password-wrapper']}>
                        <input
                            id="password"
                            type={isPasswordVisible ? 'text' : 'password'}
                            placeholder="........"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={() => setPasswordTouched(true)}
                            onKeyDown={handleKeyDown}
                            ref={passwordInputRef}
                            className={styles['input-field']}
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className={styles['password-toggle']}
                        >
                            {isPasswordVisible ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                        </button>
                    </div>
                    {passwordTouched && passwordError && (
                        <div className={styles['error-message']}>{passwordError}</div>
                    )}
                    <div className={styles['forgot-password']} onClick={handleForgotPassword}>
                        Forgot Password?
                    </div>
                </div>
                <button
                    className={styles['login-button']}
                    onClick={handleLogin}
                    disabled={!isFormValid || isLoading || isFetchingModules}
                >
                    {isLoading ? <Spinner /> : 'Login'}
                </button>
            </div>
        </div>
    );
};

export default Login;
