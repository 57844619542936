import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';
import { initialStateApi } from '@/Redux/utils';

type reInvestorDetailsType = {};

export const getReInvestorDetailsDataAction = createAsyncThunk(
    'getReInvestorDetailsDataAction',
    async (params: reInvestorDetailsType, { rejectWithValue }) => {
        try {
            const {} = params;
            const url = api.config?.getReInvestorDetailsData('');
            const response = await fetch(url, api.http.get());
            const result = await api?.afterFetchHandlers?.parseContent(
                response,
                api.http.getNoAuth()
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const getReInvestorDetailsDataReducer = createSlice({
    name: 'getReInvestorDetailsDataAction',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReInvestorDetailsDataAction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getReInvestorDetailsDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getReInvestorDetailsDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default getReInvestorDetailsDataReducer.reducer;
