import { createSlice } from '@reduxjs/toolkit';

const constraintSlice = createSlice({
  name: 'object',
  initialState: {
    data: null,
  },
  reducers: {
    addConstraintsData: (state, action) => {
    state.data = action.payload; // Action to store the object
    },
    deleteConstraintsData: (state) => {
      state.data = null; // Action to delete the object (set to null)
    },
  },
});

// Step 3: Export reducer and actions
export const { addConstraintsData, deleteConstraintsData } = constraintSlice.actions;
export default constraintSlice.reducer;
