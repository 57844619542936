import React from 'react';

interface Props {
    color?: React.CSSProperties['color'];
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
}

const EconomicGapIcon: React.FC<Props> = ({ color = '#666666', width = 20, height = 20 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1538_33990)">
                <path
                    d="M5.55232 20L1.3418 15.7895L5.55232 11.579L7.05232 13.0527L5.36811 14.7369H14.1576L12.4997 13.0527L13.9734 11.579L18.1839 15.7895L13.9734 20L12.4734 18.5263L14.1576 16.8421H5.36811L7.02601 18.5263L5.55232 20ZM2.39443 9.4737V-1.05261H4.49969V9.4737H2.39443ZM8.71022 9.4737V-1.05261H10.8155V9.4737H8.71022ZM15.026 9.4737V-1.05261H17.1313V9.4737H15.026Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1538_33990">
                    <rect width="20" height="20" fill="white" transform="translate(0.289062)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EconomicGapIcon;
