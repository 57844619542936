// currentStateOverviewData.slice.ts
import api from '../../../../api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { initialStateApi } from '../../../utils';
import { message } from 'antd';
import { toast } from 'react-toastify';

export const getProfitAnalysisData = createAsyncThunk(
    'getProfitAnalysisData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.profitAnalysis(), api.http.get());
            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            api.afterFetchHandlers.checkStatus(response);

            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const profitAnalysisData = createSlice({
    name: 'profitAnalysisData', 
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getProfitAnalysisData.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getProfitAnalysisData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getProfitAnalysisData.rejected, (state, action:any) => {
                state.isLoading = false;
                state.errorResponse = action.error;
                
            });
    },
});

export default profitAnalysisData.reducer;
