import React from 'react';

type Props = {};

const LeftArrowIcon = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={14} fill="none">
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.6}
                d="M17 7H1m0 0 6-6M1 7l6 6"
            />
        </svg>
    );
};

export default LeftArrowIcon;
