import { lazy, ComponentType } from 'react';

const lazyWithRetry = (componentImport: () => Promise<{ default: ComponentType<any> }>) =>
    lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem('page-has-been-force-refreshed') || 'false'
        );

        try {
            const component = await componentImport();

            window.localStorage.setItem('page-has-been-force-refreshed', 'false');

            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                console.log("🚀 ~ lazy ~ pageHasAlreadyBeenForceRefreshed:", pageHasAlreadyBeenForceRefreshed)
                window.localStorage.setItem('page-has-been-force-refreshed', 'true');
                window.location.reload();
                return new Promise<never>(() => {});
            }

            throw error;
        }
    });

export default lazyWithRetry;
