import api from '../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

//  initial state
import { initialStateApi } from '../../utils';

type payloadType = any;

export const getEconomicGapDataPatch: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'getEconomicGapDataPatch',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.patchEconomicGapData(), api.http.patch(payload));
            const result = await api.afterFetchHandlers.parseContent(
                response,
                api.http.patch(payload)
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const economicGapData = createSlice({
    name: 'economicGapData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getEconomicGapDataPatch.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getEconomicGapDataPatch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getEconomicGapDataPatch.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default economicGapData.reducer;
