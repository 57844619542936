import React from 'react';

interface Props {
    color?: React.CSSProperties['color'];
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
}

const TransitionFinanceIcon: React.FC<Props> = ({ color = '#666666', width = 20, height = 20 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.4717 9.10059H17.7226C17.4978 7.45166 16.812 6.05382 15.6652 4.90707C14.5184 3.76031 13.1206 3.07451 11.4717 2.84966V9.10059ZM9.67285 17.1503V2.84966C7.85903 3.07451 6.35626 3.86525 5.16454 5.22186C3.97281 6.57848 3.37695 8.17119 3.37695 10C3.37695 11.8288 3.97281 13.4215 5.16454 14.7781C6.35626 16.1348 7.85903 16.9255 9.67285 17.1503ZM11.4717 17.1503C13.1206 16.9405 14.5222 16.2584 15.6764 15.1042C16.8307 13.9499 17.5127 12.5483 17.7226 10.8994H11.4717V17.1503ZM10.5723 18.9941C9.32808 18.9941 8.15884 18.758 7.06455 18.2859C5.97026 17.8137 5.01838 17.1728 4.20891 16.3634C3.39944 15.5539 2.75861 14.602 2.28641 13.5077C1.81422 12.4134 1.57812 11.2442 1.57812 10C1.57812 8.75581 1.81422 7.58657 2.28641 6.49229C2.75861 5.398 3.39944 4.44612 4.20891 3.63665C5.01838 2.82717 5.97026 2.18634 7.06455 1.71415C8.15884 1.24196 9.32808 1.00586 10.5723 1.00586C11.8165 1.00586 12.9819 1.24196 14.0687 1.71415C15.1555 2.18634 16.1074 2.83092 16.9244 3.64789C17.7413 4.46486 18.3859 5.41674 18.8581 6.50353C19.3303 7.59032 19.5664 8.75581 19.5664 10C19.5664 11.2292 19.3303 12.3909 18.8581 13.4852C18.3859 14.5795 17.7451 15.5351 16.9356 16.3521C16.1261 17.1691 15.1743 17.8137 14.08 18.2859C12.9857 18.758 11.8165 18.9941 10.5723 18.9941Z"
                fill={color}
            />
        </svg>
    );
};

export default TransitionFinanceIcon;
