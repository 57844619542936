const AUTH_TOKEN =
    'eyJraWQiOiJrYzdlSlZ2NXBSVnZ0ZzZBY1RJc0VQY2JWVG1qSnVuSWNiZmx0UEsxZTdzPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5MzIwYzc0MC0yZTM4LTRmNjctOTVjZC0xYmQwMTE3NmExODQiLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xXzJoTVhOZk1JTiIsImNsaWVudF9pZCI6IjdsYjVqdHN0cnRsajFhczl0dWJzc243aHNtIiwib3JpZ2luX2p0aSI6ImJiOTIwNmQwLTBlMjMtNDhlMi04NTAxLTY5ZTAzNjM2YjcxYSIsImV2ZW50X2lkIjoiZGViMDQ3YTAtNTBjMy00NzY4LThiMzUtOTAxYjVmNGEyOTljIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTcyMTE5MDgxNCwiZXhwIjoxNzIxMjc3MjE0LCJpYXQiOjE3MjExOTA4MTQsImp0aSI6IjA5Mjc5MmRhLTMyZmItNDIzNS04MzBjLTY2YjU5NjRkM2IzMyIsInVzZXJuYW1lIjoiOTMyMGM3NDAtMmUzOC00ZjY3LTk1Y2QtMWJkMDExNzZhMTg0In0.g97ecfJ_4rFNo41M_H3rybXKZVCbAZPy_0hcr4ZOSyWNdsTHi9xQ5TlmcI-gNQpNTNO_16OBIN0zEsNqNd2_SChnM6nzQ1C77dJI0DCpY2vTUwjho1zG68gechAbuA8de3dwTkOHxTSIonJxnLATClDiPsIUBkLzymsC3davF4WlE2TOifeENgtY2yPX8Xv1qP6aJjGHEC6Om5g9Ttdorv_9YsZNuQvfXGRD1HO0Mmp_EkVx5XxC3UNHWRjEfyfUZNHkRqUqLQwQIpVOG6__fwnTnMGGQ1CIusnXDHgyvEnLriYa3N3i_rIZ3fcvWCQT6Z5mP8dXMlEHZZZoxJVTOw';

export const getAuthToken = () => {
    if (localStorage?.user_token) {
        return `Bearer ${localStorage?.user_token}`;
    }
    return `Bearer ${AUTH_TOKEN}`;
};

const getHttpConfig = (): RequestInit => ({
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: getAuthToken(),
    },
});

const getHttpFormConfig = (): RequestInit => ({
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        Authorization: getAuthToken(),
    },
});

const httpNoAuthConfig = {
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
    },
};

const httpNoAuthConfigFormData = {
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        // "Content-Type": "multipart/form-data",
    },
};

const getHttpDownloadFileConfig = () => ({
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
        'Content-Type': 'blob',
        Authorization: getAuthToken(),
    },
});

const http = {
    get: (headers = {}) => ({
        method: 'GET',
        ...getHttpConfig(),
        headers: {
            ...getHttpConfig().headers,
            ...headers,
        },
    }),
    getNoAuth: (headers = {}) => ({
        method: 'GET',
        ...httpNoAuthConfig,
        headers: {
            'Content-Type': 'application/json;',
        },
    }),
    post: (payload: any) => ({
        method: 'POST',
        ...getHttpConfig(),
        body: JSON.stringify(payload),
    }),
    patch: (payload: any) => ({
        method: 'PATCH',
        ...getHttpConfig(),
        body: JSON.stringify(payload),
    }),
    postForm: (formData: any) => {
        return {
            method: 'POST',
            ...getHttpFormConfig(),
            body: formData,
        };
    },
    patchForm: (formData: any) => {
        return {
            method: 'PATCH',
            ...getHttpFormConfig(),
            body: formData,
        };
    },
    put: (payload: any) => ({
        method: 'PUT',
        ...getHttpConfig(),
        body: JSON.stringify(payload),
    }),
    delete: (payload: any) => ({
        method: 'DELETE',
        ...getHttpConfig(),
        body: JSON.stringify(payload),
    }),
    deleteJson: (payload: any) => ({
        method: 'DELETE',
        ...getHttpConfig(),
        body: payload,
    }),
    deleteForm: (formData: any) => {
        return {
            method: 'DELETE',
            ...getHttpFormConfig(),
            body: formData,
        };
    },
    deleteParams: () => {
        return {
            method: 'DELETE',
            ...getHttpFormConfig(),
        };
    },

    postNoAuth: (payload: any) => ({
        method: 'POST',
        ...httpNoAuthConfig,
        body: JSON.stringify(payload),
    }),
    postNoAuthFormData: (payload: any) => ({
        method: 'POST',
        ...httpNoAuthConfigFormData,
        body: payload,
    }),
    patchNoAuth: (payload: any) => ({
        method: 'PATCH',
        ...httpNoAuthConfig,
        body: JSON.stringify(payload),
    }),
    getDownloadFiles: (headers = {}) => ({
        method: 'GET',
        ...getHttpDownloadFileConfig(),
        headers: {
            ...getHttpDownloadFileConfig().headers,
            ...headers,
        },
    }),
};

export default http;
