import React, { useState, useEffect, KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ForgotPassword.module.scss';
import { AppDispatch } from '@/Redux/store';
import { useDispatch } from 'react-redux';
import { forgotPwdDataAction } from '@/Redux/slices/authentication/forgotPassword/forgotPasswordPost.slice';
import loginImg from '@/assets/icons/login/LoginImg.svg';
import { ReactComponent as ClemLogo } from '@/assets/icons/login/clemLogo.svg';
import { ReactComponent as SustainabilityLogo } from '@/assets/icons/login/sustainabilityLogo.svg';
import Spinner from '../Spinner/Spinner';

const ForgotPassword: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailTouched, setEmailTouched] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);

    const validateEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validateForm = () => {
        let isValid = true;
        if (!email) {
            setEmailError('Email cannot be empty');
            isValid = false;
        } else if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address');
            isValid = false;
        } else {
            setEmailError('');
        }
        return isValid;
    };

    const handleSendOtp = async () => {
        if (!validateForm()) {
            toast.error('Please fix the errors');
            return;
        }

        setLoading(true);
        const payload = { email };

        try {
            await dispatch(forgotPwdDataAction(payload)).unwrap();
            toast.success('OTP sent successfully');
            navigate('/reset-password', { state: { email } });
        } catch (error) {
            toast.error('Failed to send OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendOtp();
        }
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        if (!value) {
            setEmailError('Email cannot be empty');
        } else if (!validateEmail(value)) {
            setEmailError('Please enter a valid email address');
        } else {
            setEmailError('');
        }
    };

    useEffect(() => {
        if (emailTouched) {
            validateForm();
        }
    }, [email, emailTouched]);

    return (
        <div className={styles['forgot-pwd-container']}>
            <div className={styles['image-section']}>
                <img className={styles['login-img']} alt="login img" src={loginImg} />
                <SustainabilityLogo className={styles['sustainability-logo']} />
            </div>
            <div className={styles['form-section']}>
                <ClemLogo />
                <div className={styles['heading']}>
                    <div>Reset Password</div>
                </div>
                <div className={styles['divider']} />
                <div className={styles['input-container']}>
                    <label htmlFor="email" className={styles['input-label']}>
                        Email
                    </label>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={() => {
                            setEmailTouched(true);
                            validateForm();
                        }}
                        onKeyDown={handleKeyDown}
                        className={styles['input-field']}
                    />
                    {(emailTouched || emailError) && emailError && (
                        <div className={styles['error-message']}>{emailError}</div>
                    )}
                </div>
                <button className={styles['otp-button']} onClick={handleSendOtp} disabled={loading}>
                    {loading ? <Spinner /> : 'Send OTP'}
                </button>
            </div>
        </div>
    );
};

export default ForgotPassword;
