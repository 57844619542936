import { combineReducers } from '@reduxjs/toolkit';

import dropdownData from './slices/dropdown/dropdown.slice';
import currentSectionData from './slices/currentSectionData/currentSectionData.slice';
import addCurrentSectionData from './slices/addCurrentSectionData/addCurrentSectionData.slice';
import tabCompletionInfoData from './slices/tabCompletionInfo/tabCompletionInfo.slice';
import fileUploadSlice from './slices/fileUpload/fileUpload.slice';
import polygonSlice from './slices/polygonSlice/polygon.slice';
import currentStateData from './slices/currentStateData/currentStateData.slice';
import transitionPlanData from './slices/transitionPlanData/transitionPlanData.slice';
import economicGapData from './slices/economicGapData/economicGapData.slice';
import patchEconomicGapData from './slices/economicGapData/economicGapDataPatch.slice';
import powerPlants from './slices/economicGapData/PowerPlats.slice';
import energyMixData from './slices/cleanEnergyTechMix/energyMixData.slice';
import getGraphDataForDates from './slices/getGraphDataForDates/getGraphDataForDates.slice';
import addDataForCleanEnergy from './slices/cleanEnergyTechMix/addDataForCleanEnergy/addDataForCleanEnergy.slice';
import reFinancialsData from './slices/reFinancialsData/reFinancialsData.slice';
import polygonData from './slices/getNearestPolygons/getNearestPolygons.slice';
import addPolygonData from './slices/addPolygonData/addPolygonData.slice';
import powerCurveComparisionData from './slices/comparePowerCurve/comparePowerCurve.slice';
import transitionFinanceData from './slices/transitionFinanceData/transitionFinanceData.slice';
import tfSave from './slices/transitionFinance/tfSave/tfSave.slice';
import tfApply from './slices/transitionFinance/tfApply/tfApply.slice';
import transitionFinancFrameworkData from './slices/transitionFinancing/transitionFinancingFramework/transitionFinancingFramework.slice';
import financialInstrumentsData from './slices/transitionFinancing/financialInstruments/financialInstruments.slice';
import saveCapitalStructureData from './slices/transitionFinancing/modifyCapitalStructure/saveCapitalStructure.slice';
import saveFinancialInstrumentsData from './slices/transitionFinancing/modifyFinancialInstruments/saveFinancialInstuments.slice';
import getFinancialSummary from './slices/financialSummary/financialSummary.slice';
import getPowerPurchaseAgreementDataReducer from './slices/financials/withTransitionFinancing/rePowerPurchaseAgreement/getPowerPurchaseAgreement.slice';
import getFinancialEstimateDataReducer from './slices/financials/withTransitionFinancing/financialEstimate/getFinancialEstimate.slice';
import getPowerGenerationDataReducer from './slices/financials/withTransitionFinancing/powerGeneration/getPowerGeneration.slice';
import getEmissionAvoidedDataReducer from './slices/financials/withTransitionFinancing/emissionAvoided/getEmissionAvoided.slice';
import getCashflowDataReducer from './slices/financials/withTransitionFinancing/getCashflow/getCashflowData.slice';
import getAdditionalityDataReducer from './slices/financials/withTransitionFinancingAndTransitionCredits/additionality/additionalityData/getAdditionalityData.slice';
import getCharacteristicsOfCfppDataReducer from './slices/financials/withTransitionFinancingAndTransitionCredits/additionality/characteristicsOfCfpp/getCharacteristicsOfCfpp.slice';
import getTransitionCreditsTimelineDataReducer from './slices/financials/withTransitionFinancingAndTransitionCredits/transitionCreditCashflow/transitionCreditsTimeline/transitionCreditsTimelineData.slice';
import getTransitionCreditDataReducer from './slices/financials/withTransitionFinancingAndTransitionCredits/transitionCreditCashflow/totalTransitionCredits/getTotalTransitionCreditData.slice';
import getTransitionCreditCashflowDataReducer from './slices/financials/withTransitionFinancingAndTransitionCredits/transitionCreditCashflow/transitionCreditCashflowData/getTransitionCreditCashflowData.slice';
import CardInfo from './slices/CardInfo/CardInfo.slice';
import cleanEnergyTechMixOverview from './slices/cleanEnergyTechMix/overview/overview.slice';
import constraints from './slices/cleanEnergyTechMix/constraints/constraints.slice';
import addLandDetails from './slices/addLandDetails/addLandDetails.slice';
import constraintsDropdownData from './slices/cleanEnergyTechMix/constraintsDropdown/constraintsDropdown.slice';
import getTimelineChartDataReducer from './slices/financials/withTransitionFinancing/timelineChart/getTimelineChartData.slice';
import getReInvestorDetailsDataReducer from './slices/financials/withTransitionFinancing/reInvestorDetails/getReInvestorDetailsData.slice';
import currentStateCombinedReducers from './slices/currentState/currentStateCombinedReducer.reducer';
import finalEnergyMixOptions from './slices/cleanEnergyTechMix/finalEnergyMixOptions/finalEnergyMixOptions.slice';
import cleanEnergyTechMixCardInfoAndConstraints from './slices/cleanEnergyTechMix/cardInfoAndConstraints/cardInfoAndConstraints.slice';
import PostTransitionPlan from './slices/transitionPlanData/PostTransitionPlan/PostTransitionPlan.slice';
import investorDetailsDataReducer from './slices/financials/withTransitionFinancing/reInvestorDetails/updateInvestorData/updateInvestorData.slice';
import equipmentDropdown from './slices/EquipmentDropdown/equipmentDropdown.slice';
import cleanEnergyTechMixReducers from './slices/cleanEnergyTechMix/cleanEnergyTechMixReducers.reducer';
import applyDataSlice from './slices/financials/withTransitionFinancing/applyData/applyData.slice';
import getModuleAccess from './slices/authentication/moduleAccessGet/moduleAccessGet.slice';
import fetchFinancialsAssumptions from './slices/financials/withTransitionFinancing/assumptions/assumptions.slice';

export const rootReducer = combineReducers({
    dropdownData,
    tabCompletionInfoData,
    currentSectionData,
    addCurrentSectionData,
    fileUploadSlice,
    polygonSlice,
    currentStateData,
    transitionPlanData,
    constraints,
    cleanEnergyTechMixOverview,
    finalEnergyMixOptions,
    constraintsDropdownData,
    cleanEnergyTechMixCardInfoAndConstraints,
    reFinancialsData,
    energyMixData,
    addPolygonData,
    CardInfo,
    addLandDetails,
    economicGapData,
    getGraphDataForDates,
    addDataForCleanEnergy,
    powerPlants,
    transitionFinanceData,
    tfSave,
    tfApply,
    getFinancialSummary,
    transitionFinancFrameworkData,
    financialInstrumentsData,
    polygonData,
    fetchFinancialsAssumptions,
    ...cleanEnergyTechMixReducers,
    PostTransitionPlan,
    investorDetailsDataReducer,
    powerCurveComparisionData,
    saveCapitalStructureData,
    saveFinancialInstrumentsData,
    ...currentStateCombinedReducers,
    getTimelineChartDataReducer,
    getPowerPurchaseAgreementDataReducer,
    getFinancialEstimateDataReducer,
    getReInvestorDetailsDataReducer,
    getPowerGenerationDataReducer,
    getEmissionAvoidedDataReducer,
    getCashflowDataReducer,
    getAdditionalityDataReducer,
    getCharacteristicsOfCfppDataReducer,
    getTransitionCreditsTimelineDataReducer,
    getTransitionCreditDataReducer,
    getTransitionCreditCashflowDataReducer,
    patchEconomicGapData,
    applyDataSlice,
    equipmentDropdown,
    ...currentStateCombinedReducers,
    getModuleAccess,
});
