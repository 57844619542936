import { createSelector } from 'reselect';
import { RootState } from '../../../store';

const selectModuleAccessStore = (state: RootState) => state.getModuleAccess;

export const moduleAccessResponse = createSelector(
    [selectModuleAccessStore],
    (moduleAccessState) => moduleAccessState?.response
);

export const selectFinancialSummaryLoading = createSelector(
    [selectModuleAccessStore],
    (moduleAccessState) => moduleAccessState.isLoading
);
