import Loader from '@/components/commons/Loader/Loader';
import classes from './LazyLoader.module.scss';


type Props = {}

const LazyLoader = (props: Props) => {
  return (
    <div className={classes['lazyLoader']}>
        <Loader/>
    </div>
  )
}

export default LazyLoader