import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';
import { initialStateApi } from '@/Redux/utils';

type transitionCreditType = {};

export const getTotalTransitionCreditDataAction = createAsyncThunk(
    'getTotalTransitionCreditDataAction',
    async (params: transitionCreditType, { rejectWithValue }) => {
        try {
            const {} = params;
            const url = api.config.getTransitionCreditData();
            const response = await fetch(url, api.http.get());
            const result = await api.afterFetchHandlers.parseContent(
                response,
                api.http.getNoAuth()
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const getTransitionCreditDataReducer = createSlice({
    name: 'getTotalTransitionCreditDataAction',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTotalTransitionCreditDataAction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTotalTransitionCreditDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getTotalTransitionCreditDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default getTransitionCreditDataReducer.reducer;
