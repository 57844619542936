import { initialStateApi } from '@/Redux/utils';
import api from '@/api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

type payloadType = {
    payload: Record<string, any>;
};

export const patchTfApply: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'patchTfApply',
    async ({ payload }, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.patchTfApply(), api.http.patch(payload));

            const result = await api.afterFetchHandlers.checkStatus(response);

            return result;
        } catch (error: any) {
            if (!error.ok) {
                const result = (await api.afterFetchHandlers.parseContent(error, api.http.post(payload))) || {};
                toast.error(result.message || 'Failed to save!');
            }
            rejectWithValue(error);
        }
    }
);

const tfApply = createSlice({
    name: 'tfApply',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(patchTfApply.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(patchTfApply.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(patchTfApply.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
                state.isError = true;
            });
    },
});

export default tfApply.reducer;
