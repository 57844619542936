import api from "@/api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "@/Redux/utils";

type payloadType = {
  params?: Record<string, (string | any)>;
};

export const getConstraintsDropdown: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getConstraintsDropdown",
    async ({params}, { rejectWithValue }) => {
      try {
        
        const response = await fetch(
          api.config.constraintsDropdown(params),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  );

const constraintsDropdown = createSlice({
  name: "constraintsDropdown",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getConstraintsDropdown.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getConstraintsDropdown.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getConstraintsDropdown.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default constraintsDropdown.reducer;
