//resetPassword.slice.ts
import { createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';

type resetPasswordPayload = {
    email: string;
    verificationCode: string;
    newPassword: string;
};

export const resetPwdDataAction: AsyncThunk<any, resetPasswordPayload, {}> = createAsyncThunk(
    'resetPwdDataAction',
    async (payload: resetPasswordPayload, { rejectWithValue }) => {
        try {
            const url = api.config?.postConfirmPasswordReset();
            const response = await fetch(url, api.http.post(payload));

            if (!response.ok) {
                const errorData = await response.json();
                return rejectWithValue(errorData.message || 'An error occurred');
            }

            const result = await api?.afterFetchHandlers?.parseContent(
                response,
                api.http.post(payload)
            );
            return result;
        } catch (error: any) {
            return rejectWithValue(error.message || 'Network error');
        }
    }
);
