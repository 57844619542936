// import library components
import api from '../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '../../utils';

type payloadType = {};

export const getEquipmentDropdownData: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'getEquipmentDropdownData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.equipmentDropdown(), api.http.get());
            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            rejectWithValue(error);
        }
    }
);

const equipmentDropdown = createSlice({
    name: 'equipmentDropdown',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getEquipmentDropdownData.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getEquipmentDropdownData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getEquipmentDropdownData.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default equipmentDropdown.reducer;
