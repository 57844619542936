const dataCollectionSubTabKeys: Record<string, string> = {
    details: "details",
    technical: "technical",
    coal: "coal",
    economics: "economics",
    cfppfinancials: "cfppfinancials",
    investors: "investors",
    transition: "transition"
};

export default dataCollectionSubTabKeys;