import { useLocation } from 'react-router-dom';
import { HEADER_CONFIG } from '@/utils/constants/headerConfig';
import { useNavigate } from 'react-router-dom';
import LeftArrowIcon from '@/assets/icons/LeftArrowIcon';

import styles from './Header.module.scss';

import { Divider } from 'antd';

import HeaderRightSectionComponent from '@/pages/CurrentState/HeaderRightSectionIconsComponent/HeaderRightSectionComponent';
interface HeaderProps {
    currencySelectorIcon?: React.ComponentType;
    notificationIcon?: React.ComponentType;
    profileImage?: string;
}

const Header = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const { state } = location;

    const { headerData: stateHeaderData } = state || {};

    const currentPath = location.pathname;

    const headerData = HEADER_CONFIG[currentPath];

    const { title, subTitle, backNav, rightSectionComponent } = headerData || {};

    const {
        title: stateTitle,
        subTitle: stateSubTitle,
        backNav: stateBackNav,
    } = stateHeaderData || {};

    const showBackIcon = backNav || stateBackNav;

    if (!headerData && !stateHeaderData) return null;

    return (
        <div className={styles.header}>
            <div className={styles['header-info']}>
                <div className={styles['inner-container']}>
                    {showBackIcon ? (
                        <span
                            style={{ cursor: 'pointer', marginTop: '6px' }}
                            onClick={() => navigate(-1)}
                        >
                            <LeftArrowIcon />
                        </span>
                    ) : null}

                    <span>
                        <span className={styles['header-title']}>{stateTitle || title}</span>
                        <span className={styles['header-subtitle']}>
                            {stateSubTitle || subTitle}
                        </span>
                    </span>
                </div>
            </div>

            <div className="headersRightIconsWrap">
                <HeaderRightSectionComponent />
            </div>
        </div>
    );
};

export default Header;
