import './Loader.scss';
import transparentLoader from '../../../assets/icons/loaders/transparent_loader.gif';

type Props = {
    imgStyles?: React.CSSProperties;
    style?: React.CSSProperties;
};

const Loader = (props: Props) => {
    const { imgStyles, style } = props;

    return (
        <div className="loader-container" style={style}>
            <img
                className="transparent-loader"
                src={transparentLoader}
                alt="loader"
                style={imgStyles}
            />
        </div>
    );
};

export default Loader;
