import api from '@/api/index';
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

import { initialStateApi } from "../../../utils";

type payloadType = any;

export const PostTransitionPlanAction: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "PostTransitionPlan",
    async (payload, { rejectWithValue }) => {
      try {

        const response = await fetch(api.config.PostTransitionPlan(), api.http.post(payload));
        const result = await api.afterFetchHandlers.parseContent(
            response,
            api.http.post(payload)
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const PostTransitionPlan = createSlice({
  name: "postTransitionplanData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PostTransitionPlanAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(PostTransitionPlanAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(PostTransitionPlanAction.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default PostTransitionPlan.reducer;


