import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';
import { initialStateApi } from '@/Redux/utils';

type powerPurchaseAgreementType = {};

export const getPowerPurchaseAgreementDataAction = createAsyncThunk(
    'getPowerPurchaseAgreementDataAction',
    async (params: powerPurchaseAgreementType, { rejectWithValue }) => {
        try {
            const {} = params;
            const url = api.config?.getPowerPurchaseAgreementData();
            const response = await fetch(url, api.http.get());
            const result = await api?.afterFetchHandlers?.parseContent(
                response,
                api.http.getNoAuth()
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const getPowerPurchaseAgreementDataReducer = createSlice({
    name: 'getPowerPurchaseAgreementDataAction',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPowerPurchaseAgreementDataAction.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getPowerPurchaseAgreementDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getPowerPurchaseAgreementDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default getPowerPurchaseAgreementDataReducer.reducer;
