import React from 'react';

type Props = {};

const NotificationIcon = (props: Props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={22} height={24} fill="none">
            <path
                fill="#707C89"
                d="M6.422 21.89A5.555 5.555 0 0 0 10.8 24a5.556 5.556 0 0 0 4.378-2.11 32.59 32.59 0 0 1-8.756 0ZM18.9 8.4v.845a5.27 5.27 0 0 0 .83 2.849l1.33 2.067c1.213 1.889.287 4.456-1.824 5.052a30.954 30.954 0 0 1-16.872 0C.254 18.617-.673 16.05.54 14.161l1.33-2.067c.544-.85.833-1.84.831-2.85V8.4c0-4.64 3.627-8.4 8.1-8.4C15.272 0 18.9 3.76 18.9 8.4Z"
            />
        </svg>
    );
};

export default NotificationIcon;
