import dataCollectionSubTabKeys from "./dataCollectionSubTabKeys";

const { details, technical, coal, economics, cfppfinancials, investors, transition} = dataCollectionSubTabKeys;

type DataCollectionSubTab = {
  key: string;
  title: string;
};

const dataCollectionSubTabs: DataCollectionSubTab[] = [
  { key: details, title: "CFPP Details" },
  { key: technical, title: "CFPP Technical" },
  { key: coal, title: "CFPP Coal" },
  { key: economics, title: "CFPP Economics" },
  { key: cfppfinancials, title: "CFPP Financials" },
  { key: investors, title: "CFPP Investors" },
  { key: transition, title: "Transition Approach" }
];

export default dataCollectionSubTabs;
