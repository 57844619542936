import api from '../../../../api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '../../../utils';
import { message } from 'antd';
import { toast } from 'react-toastify';
type financialAssets = {
    year?: number;
    plf?: number;
    aux?: number;
    paf?: number;
    unit?: number;
    emissionFactor?: number;
};
export const getfinancialAssets = createAsyncThunk(
    'getfinancialAssets',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.financialAssets(), api.http.get());

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            api.afterFetchHandlers.checkStatus(response);
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            rejectWithValue(error);
        }
    }
);


const financialAssets = createSlice({
    name: 'financialAssets',
    initialState: initialStateApi,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(getfinancialAssets.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getfinancialAssets.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getfinancialAssets.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default financialAssets.reducer;
