import React, { forwardRef } from 'react';
import styles from './Reporting.module.scss'
import { ReactComponent as NotFoundImg } from '@/assets/icons/notFoundImg.svg';

const Reporting = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div ref={ref} className={styles['reporting-container']}>
            <NotFoundImg />
        </div>
    );
});

export default Reporting;
