// getGraphDataForDates.slice.ts
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import api from '../../../api';
import { initialStateApi } from '../../utils';

type payloadType = {
  params?: Record<string, (string | any)>;
};

export const getGraphDataForDates: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'getGraphDataForDates',
    async ({params}, { rejectWithValue }) => {
        try {
            const url = api.config.getGraphDataForDates(params);

            const response = await fetch(url, api.http.get());

            await api.afterFetchHandlers.checkStatus(response)

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());

            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const getGraphDataForDatesSlice = createSlice({
    name: 'getGraphDataForDates',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGraphDataForDates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getGraphDataForDates.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getGraphDataForDates.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default getGraphDataForDatesSlice.reducer;
