import {
    createSlice,
    createAsyncThunk,
    SerializedError,
    PayloadAction,
    ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { message } from 'antd';

export enum APILoadingIdentifiers {
    INITIAL_GET = 'get',
    WACC_APPLY = 'waccApply',
    APPLY_GROSS_POWER = 'applyGrossPower',
    APPLY_AUX = 'auxApplyLoading',
    APPLY_EMISSION = 'applyEmissionLoading',
    APPLY_VARIABLEANDFIXEDTARRIF = 'applyVariableAndFixedTarrif',
    APPLY_TOTALTARRIF = 'applyTotalTarrif',
    APPLY_FUEL_COST = 'applyFuelCost',
    APPLY_OM_EXPENSES = 'applyOmExpenses',
    APPLY_WDV = 'applyWdv',
    APPLY_CORPORATE_TAX = 'applyCorporateTax',
    APPLY_DEBT_SCHEDULE = 'applyDebtSchedule',
    APPLY_SALVAGE_VALUE = 'applySalvageValue',
    APPLY_WORKING_CAPITAL = 'applyWorkingCapital',
    APPLY_CAPEX = 'applyCapex',
}

type InitialStateType = {
    isLoading: { [key in APILoadingIdentifiers]?: boolean };
    isError: boolean;
    response: any;
    errorResponse: SerializedError | any | null;
};

type FetchResponse = {
    result: any;
    identifier: APILoadingIdentifiers;
};

type FetchError = {
    error: SerializedError | any;
    identifier: APILoadingIdentifiers;
};

type ThunkApiConfig = {
    rejectValue: FetchError;
};

const initialStateApi: InitialStateType = {
    isLoading: {},
    isError: false,
    response: {
        revenueAndExpenseActiveYear: null,
    },
    errorResponse: null,
};

export const getPowerGenerationData = createAsyncThunk<
    FetchResponse,
    { data: any; identifier: APILoadingIdentifiers; signal?: AbortSignal },
    ThunkApiConfig
>('getPowerGenerationData', async ({ data, identifier, signal }, { rejectWithValue }) => {
    try {
        const response = await fetch(api.config.powerGeneration(), {
            ...api.http.post(data),
            signal,
        });
        const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
        api.afterFetchHandlers.checkStatus(response);

        return { result, identifier };
    } catch (error: any) {
        if (error.name === 'AbortError') {
            return rejectWithValue({ error: 'Request was canceled', identifier });
        }
        return rejectWithValue({ error, identifier });
    }
});

const powerGenerationData = createSlice({
    name: 'powerGenerationData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
        builder
            .addCase(getPowerGenerationData.pending, (state, action) => {
                state.isLoading[action.meta.arg.identifier] = true;
            })
            .addCase(
                getPowerGenerationData.fulfilled,
                (state, action: PayloadAction<FetchResponse>) => {
                    state.isLoading[action.payload.identifier] = false;
                    state.response = action.payload.result;
                    if (action.payload.identifier !== APILoadingIdentifiers.INITIAL_GET) {
                        toast.success(`Changes applied successfully`);
                    }
                }
            )
            .addCase(getPowerGenerationData.rejected, (state, action) => {
                const { identifier } = action.meta.arg;
                state.isLoading[identifier] = false;
                state.errorResponse = action.payload?.error || action.error;

                if (
                    action.payload?.error !== 'Request was canceled' ||
                    identifier !== APILoadingIdentifiers.INITIAL_GET
                ) {
                    toast.error(`Something went wrong!`);
                }
            });
    },
});

export default {
    powerGenerationData: powerGenerationData.reducer,
};
