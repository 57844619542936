import React, { forwardRef } from 'react';
import styles from './SdgGoals.module.scss';
import { ReactComponent as NotFoundImg } from '@/assets/icons/notFoundImg.svg';

const SdgGoals = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div ref={ref} className={styles['sdg-goals-container']}>
            <NotFoundImg />
        </div>
    );
});

export default SdgGoals;
