import './App.scss';
import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ToastWrapper from './components/commons/ToastWrapper/ToastWrapper';
import Layout from './pages/Layout/Layout';
import LazyLoader from './pages/MainContent/LazyLoader/LazyLoader';
import Login from './pages/Layout/Authentication/Login/Login';
import ForgotPassword from './pages/Layout/Authentication/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/Layout/Authentication/ResetPassword/ResetPassword';

import { ROUTES as routes } from './utils/constants/routes';

import NotFound from '@/pages/NotFound/NotFound';

import sideBarKeys from './utils/data/sideBarKeys';
import ProjectDocument from './pages/TransitionSummary/WithTransitionFinancingAndTransitionCredits/ProjectDocument/ProjectDocument';
import JustTransition from './pages/TransitionSummary/WithTransitionFinancingAndTransitionCredits/JustTransition/JustTransition';
import SdgGoals from './pages/TransitionSummary/WithTransitionFinancingAndTransitionCredits/SdgGoals/SdgGoals';
import Reporting from './pages/TransitionSummary/WithTransitionFinancingAndTransitionCredits/Reporting/Reporting';
import lazyWithRetry from './utils/helper/LazyLoadWithRetry';

const EnergyMix = lazyWithRetry(() => import('./pages/CleanEnergyTechMix/EnergyMix/EnergyMix'));
const CfppDetails = lazyWithRetry(() => import('@/pages/Cfpp/Cfpp'));
const CfppFinancials = lazyWithRetry(() => import('@/pages/Cfpp/CfppFinancials/CfppFinancials'));
const CfppCoal = lazyWithRetry(() => import('@/pages/Cfpp/CfppCoal/CfppCoal'));
const CfppEconomics = lazyWithRetry(() => import('@/pages/Cfpp/CfppEconomics/CfppEconomics'));
const CfppTechnical = lazyWithRetry(() => import('@/pages/Cfpp/CfppTechnical/CfppTechnical'));
const CfppInvestees = lazyWithRetry(() => import('@/pages/Cfpp/CfppInvestees/CfppInvestees'));
const CfppTransition = lazyWithRetry(() => import('@/pages/Cfpp/CfppTransition/CfppTransition'));
const CurrentState = lazyWithRetry(() => import('@/pages/CurrentState/CurrentState'));
const TransitionPlan = lazyWithRetry(() => import('@/pages/TransitionPlan/TransitionPlan'));
const EconomicGap = lazyWithRetry(() => import('@/pages/TransitionPlan/EconomicGap/EconomicGap'));
const CleanEnergyTechMix = lazyWithRetry(() => import('@/pages/CleanEnergyTechMix/CleanEnergyTechMix'));
const TransitionFinancing = lazyWithRetry(() => import('@/pages/TransitionFinancing/TransitionFinancing'));
const FutureState = lazyWithRetry(() => import('@/pages/TransitionFinancing/FutureState/FutureState'));
const TransitionSummary = lazyWithRetry(() => import('@/pages/TransitionSummary/TransitionSummary'));
const Additionality = lazyWithRetry(
    () =>
        import(
            '@/pages/TransitionSummary/WithTransitionFinancingAndTransitionCredits/Additionality/Additionality'
        )
);
const TransitionCreditCashflow = lazyWithRetry(
    () =>
        import(
            '@/pages/TransitionSummary/WithTransitionFinancingAndTransitionCredits/TransitionCreditCashflow/TransitionCreditCashflow'
        )
);
const FinancialSummary = lazyWithRetry(() => import('@/pages/FinancialSummary/FinancialSummary'));

const { cleanEnergyTechMix } = sideBarKeys;

const routesArray = [
    {
        path: '/',
        element: <Login />,
    },
    {
        path: '/forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
    },
    {
        path: '/',
        element: <Layout />,
        errorElement:<NotFound errorCode={500}/>,
        children: [
            {
                path: routes.DATA_COLLECTION.INDEX,
                element: <CfppDetails />,
                children: [
                    {
                        path: routes.DATA_COLLECTION.DETAILS,
                        element: (
                            <Suspense fallback={<LazyLoader />}>
                                <CfppDetails />
                            </Suspense>
                        ),
                    },
                    {
                        path: routes.DATA_COLLECTION.TECHNICAL,
                        element: (
                            <Suspense fallback={<LazyLoader />}>
                                <CfppTechnical />
                            </Suspense>
                        ),
                    },
                    {
                        path: routes.DATA_COLLECTION.COAL,
                        element: (
                            <Suspense fallback={<LazyLoader />}>
                                <CfppCoal />
                            </Suspense>
                        ),
                    },
                    {
                        path: routes.DATA_COLLECTION.ECONOMICS,
                        element: (
                            <Suspense fallback={<LazyLoader />}>
                                <CfppEconomics />
                            </Suspense>
                        ),
                    },
                    {
                        path: routes.DATA_COLLECTION.TRANSITION,
                        element: (
                            <Suspense fallback={<LazyLoader />}>
                                <CfppTransition />
                            </Suspense>
                        ),
                    },
                    {
                        path: routes.DATA_COLLECTION.INVESTORS,
                        element: (
                            <Suspense fallback={<LazyLoader />}>
                                <CfppInvestees />
                            </Suspense>
                        ),
                    },
                    {
                        path: routes.DATA_COLLECTION.FINANCIALS,
                        element: (
                            <Suspense fallback={<LazyLoader />}>
                                <CfppFinancials />
                            </Suspense>
                        ),
                    },
                ],
            },

            {
                path: routes.CURRENT_STATE,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <CurrentState />
                    </Suspense>
                ),
            },
            {
                path: routes.TRANSITION_PLAN,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <TransitionPlan />
                    </Suspense>
                ),
            },
            {
                path: routes.ECONOMIC_GAP,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        {/* <EconomicGap /> */}
                        <TransitionPlan />
                    </Suspense>
                ),
            },
            {
                path: routes.CLEAN_ENERGY_TECH_MIX,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <CleanEnergyTechMix />
                    </Suspense>
                ),
            },
            {
                path: `/${cleanEnergyTechMix}/energy-mix/:phase/:approach/:capacity/:latitude?/:longitude?`,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <EnergyMix />
                    </Suspense>
                ),
            },
            {
                path: routes.TRANSITION_FINANCING.TRANSITION_FINANCE,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <TransitionFinancing />
                    </Suspense>
                ),
            },
            {
                path: routes.TRANSITION_FINANCING.FUTURE_STATE,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <FutureState />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.INDEX,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <TransitionSummary />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <TransitionSummary />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.INDEX,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <Additionality />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.ADDITIONALITY,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <Additionality />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.TRANSITION_CREDIT_CASHFLOW,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <TransitionCreditCashflow />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.PROJECT_DOCUMENT,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <ProjectDocument />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.JUST_TRANSITION,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <JustTransition />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.SDG_GOALS,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <SdgGoals />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.REPORTING,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <Reporting />
                    </Suspense>
                ),
            },
            {
                path: routes.FINANCIAL_SUMMARY,
                element: (
                    <Suspense fallback={<LazyLoader />}>
                        <FinancialSummary />
                    </Suspense>
                ),
            },
        ],
    },
    {
        path: '*',
        element: <NotFound />
    },
];

const router = createBrowserRouter(routesArray);

function App() {
    return (
        <div className="App">
            <ToastWrapper />
            <Suspense fallback={<LazyLoader />}>
                <RouterProvider router={router} />
            </Suspense>
        </div>
    );
}

export default App;

