// import CurrencySelectorIcon from '@/assets/icons/Energymix/CurrencySelectorIcon';
// import NotificationIcon from '@/assets/icons/headerIcons/NotificationIcon';
// import ProfileImage from '../../../assets/images/ProfileImage.jpeg';
// import React from 'react';
// import classes from './headerRightSectionIconComponent.module.scss';
// import { Dropdown } from 'antd';

// type Props = {};
// const items: any = [
//     {
//         key: '2',
//         label: <div>INR (₹)</div>,
//         disabled: true, //only dollars for now
//     },
//     {
//         key: '1',
//         label: <div>USD ($) </div>,
//     },
// ];

// const HeaderRightSectionComponent = (props: Props) => {
//     return (
//         <div className={classes['iconWrap']}>
//             <Dropdown menu={{ items }} trigger={['click']}>
//                 <div className="selectorIcon">
//                     <CurrencySelectorIcon />
//                 </div>
//             </Dropdown>
//             <NotificationIcon />
//             <img src={ProfileImage} alt="" className={classes['image']} />
//         </div>
//     );
// };

// export default HeaderRightSectionComponent;

import CurrencySelectorIcon from '@/assets/icons/Energymix/CurrencySelectorIcon';
import NotificationIcon from '@/assets/icons/headerIcons/NotificationIcon';
import ProfileImage from '../../../assets/images/ProfileImage.jpeg';
import { ReactComponent as Profile } from '@/assets/icons/profile.svg';
import { ReactComponent as Logout } from '@/assets/icons/logout.svg';
import React, { useState } from 'react';
import classes from './headerRightSectionIconComponent.module.scss';
import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';

const items: any = [
    {
        key: '2',
        label: <div>INR (₹)</div>,
        disabled: true,
    },
    {
        key: '1',
        label: <div>USD ($) </div>,
    },
];

const HeaderRightSectionComponent = () => {
    const navigate = useNavigate();

    const items2: any = [
        {
            key: '2',
            label: (
                <div className={classes['profile-logout']}>
                    <Profile />
                    <div>Profile</div>
                </div>
            ),
        },
        {
            key: '1',
            label: (
                <div
                    style={{
                        height: '32px',
                        color: '#DA3434',
                        backgroundColor: 'rgba(217, 45, 37, 0.12)',
                    }}
                    className={classes['profile-logout']}
                    onClick={() => {
                        localStorage.removeItem('user_token');
                        navigate('/');
                    }}
                >
                    <Logout />
                    <div>Logout</div>
                </div>
            ),
        },
    ];

    return (
        <>
            <div className={classes['iconWrap']}>
                <Dropdown menu={{ items }} trigger={['click']}>
                    <div className={classes["selectorIcon"]}>
                        <CurrencySelectorIcon />
                    </div>
                </Dropdown>
                <NotificationIcon />
                <Dropdown menu={{ items: items2 }} trigger={['click']}>
                    <img src={ProfileImage} alt="Profile" className={classes['image']} />
                </Dropdown>
            </div>
        </>
    );
};

export default HeaderRightSectionComponent;
