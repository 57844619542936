import api from "@/api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit"

// initial state
import { initialStateApi } from "@/Redux/utils";

type payloadType = {
    type: string,
    senior_debt?:number
    junior_debt?:number
    equity_value?:number
}

export const getFinancialInstrumentData: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk("getFinancialInstrumentData",
        async ({ type, senior_debt=0 , junior_debt =0 , equity_value=0}, { rejectWithValue }) => {

            try {
                const response = await fetch(
                    api.config.getTransitionFinanceFrameworkData(type , senior_debt , junior_debt,equity_value),
                    api.http.get()
                );
                const result = await api.afterFetchHandlers.parseContent(
                    response,
                    api.http.get()
                );
                api.afterFetchHandlers.checkStatus(response);
                return result;
            }
            catch (error: any) {
                if (!error.response) {
                    throw error;
                }
                rejectWithValue(error)
            }
        });


const financialInstrumentsData = createSlice({
    name: "financialInstrumentsData",
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getFinancialInstrumentData.pending, (state, _) => {
                state.isLoading = true
            })
            .addCase(getFinancialInstrumentData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getFinancialInstrumentData.rejected, (state, action) => {
                state.isLoading = false;
                state.response = action.error
            })
    }
})


export default financialInstrumentsData.reducer