// polygon.slice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PolygonState {
    polygons: number[][][];
}

const initialState: PolygonState = {
    polygons: [],
};

const polygonSlice = createSlice({
    name: 'polygon',
    initialState,
    reducers: {
        addPolygon(state, action: PayloadAction<number[][][]>) {
            state.polygons.push(...action.payload);
        },
        deletePolygon(state, action: PayloadAction<number>) {
            state.polygons.splice(action.payload, 1);
        },
        clearPolygons(state) {
            state.polygons = [];
        },
    },
});

export const { addPolygon, deletePolygon, clearPolygons } = polygonSlice.actions;

export default polygonSlice.reducer;
