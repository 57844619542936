import React, { useState, useEffect, KeyboardEvent } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './ResetPassword.module.scss';
import { AppDispatch } from '@/Redux/store';
import { useDispatch } from 'react-redux';
import { resetPwdDataAction } from '@/Redux/slices/authentication/resetPassword/resetPassword.slice';
import loginImg from '@/assets/icons/login/LoginImg.svg';
import { ReactComponent as ClemLogo } from '@/assets/icons/login/clemLogo.svg';
import { ReactComponent as SustainabilityLogo } from '@/assets/icons/login/sustainabilityLogo.svg';
import Spinner from '../Spinner/Spinner';
import { ReactComponent as ShowPasswordIcon } from '@/assets/icons/login/showPassword.svg';
import { ReactComponent as HidePasswordIcon } from '@/assets/icons/login/hidePassword.svg';

const ResetPassword: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();

    const emailFromLocation = (location.state as any)?.email || '';

    const [email, setEmail] = useState(emailFromLocation);
    const [verificationCode, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const [loading, setLoading] = useState(false);
    const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

    useEffect(() => {
        if (password && newPassword && password !== newPassword) {
            setPasswordError('Passwords do not match');
        } else {
            setPasswordError('');
        }
    }, [password, newPassword]);

    const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
    const validateOtp = (otp: string) => /^\d{6}$/.test(otp);
    const validatePasswordComplexity = (password: string) =>
        /^(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'<>,.?/~`])(?!.*\s)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
            password
        );

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
        setEmailError(validateEmail(value) ? '' : 'Enter valid format');
    };

    const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setOtp(value);
        setOtpError(validateOtp(value) ? '' : '"Verification Code" must be 6 digits');
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
        if (newPassword && value !== newPassword) {
            setPasswordError('Passwords do not match');
        }
    };

    const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setNewPassword(value);
        if (password && value !== password) {
            setPasswordError('Passwords do not match');
        }
        if (!validatePasswordComplexity(value)) {
            setPasswordError(
                'New password must contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter'
            );
        } else {
            setPasswordError('');
        }
    };

    const handleSubmit = async () => {
        let hasError = false;

        if (!validateEmail(email)) {
            setEmailError('Enter valid format');
            hasError = true;
        }
        if (!validateOtp(verificationCode)) {
            setOtpError('"Verification Code" must be 6 digits');
            hasError = true;
        }
        if (!validatePasswordComplexity(newPassword)) {
            setPasswordError(
                'New password must contain at least 1 number, 1 special character, 1 uppercase letter, and 1 lowercase letter'
            );
            hasError = true;
        }

        if (password && newPassword && password !== newPassword) {
            setPasswordError('Passwords do not match');
            hasError = true;
        }

        if (hasError) {
            toast.error('Please fix the errors before saving');
            return;
        }

        setLoading(true);
        const payload = {
            email,
            verificationCode,
            newPassword,
        };

        try {
            await dispatch(resetPwdDataAction(payload)).unwrap();
            toast.success('Password reset successfully');
            navigate('/');
        } catch (error) {
            toast.error('Invalid Verification code');
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, nextField: () => void) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            nextField();
        }
    };

    const focusNextField = (index: number) => {
        const inputs = document.querySelectorAll('input');
        if (index < inputs.length - 1) {
            (inputs[index + 1] as HTMLInputElement).focus();
        } else {
            handleSubmit();
        }
    };

    const toggleOldPasswordVisibility = () => {
        setIsOldPasswordVisible((prev) => !prev);
    };

    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible((prev) => !prev);
    };

    return (
        <div className={styles['reset-pwd-container']}>
            <div className={styles['image-section']}>
                <img className={styles['login-img']} alt="login img" src={loginImg} />
                <SustainabilityLogo className={styles['sustainability-logo']} />
            </div>
            <div className={styles['form-section']}>
                <ClemLogo />
                <div className={styles['heading']}>
                    <div>Reset Password</div>
                </div>
                <div className={styles['divider']} />
                <div className={styles['input-container']}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        className={styles['input-field']}
                        disabled
                        onKeyDown={(e) => handleKeyDown(e, () => focusNextField(0))}
                    />
                    {emailError && <div className={styles['error-message']}>{emailError}</div>}
                </div>
                <div className={styles['input-container']}>
                    <input
                        type="text"
                        placeholder="OTP"
                        value={verificationCode}
                        onChange={handleOtpChange}
                        className={styles['input-field']}
                        onKeyDown={(e) => handleKeyDown(e, () => focusNextField(1))}
                    />
                    {otpError && <div className={styles['error-message']}>{otpError}</div>}
                </div>
                <div className={styles['input-container']}>
                    <div className={styles['password-wrapper']}>
                        <input
                            type={isOldPasswordVisible ? 'text' : 'password'}
                            placeholder="New Password"
                            value={password}
                            onChange={handlePasswordChange}
                            className={styles['input-field']}
                            onKeyDown={(e) => handleKeyDown(e, () => focusNextField(2))}
                        />
                        <button
                            type="button"
                            onClick={toggleOldPasswordVisibility}
                            className={styles['password-toggle']}
                        >
                            {isOldPasswordVisible ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                        </button>
                    </div>
                </div>
                <div className={styles['input-container']}>
                    <div className={styles['password-wrapper']}>
                        <input
                            type={isNewPasswordVisible ? 'text' : 'password'}
                            placeholder="Confirm New Password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            className={styles['input-field']}
                            onKeyDown={(e) => handleKeyDown(e, () => focusNextField(3))}
                        />
                        <button
                            type="button"
                            onClick={toggleNewPasswordVisibility}
                            className={styles['password-toggle']}
                        >
                            {isNewPasswordVisible ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                        </button>
                    </div>
                    {passwordError && (
                        <div className={styles['error-message']}>{passwordError}</div>
                    )}
                </div>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className={styles['submit-button']}
                    disabled={loading}
                >
                    {loading ? <Spinner /> : 'Reset Password'}
                </button>
            </div>
        </div>
    );
};

export default ResetPassword;
