import styles from './NotFound.module.scss';

type NotFoundProps = {
    errorCode?: 404 | 500 | 403;
    errorMessage?: string;
};

const NotFound: React.FC<NotFoundProps> = ({ errorCode = 404, errorMessage }) => {
    const messages = {
        404: 'Page not found',
        500: 'Something went wrong, please try again',
        403: 'Access denied',
    };

    return (
        <div className={styles['not-found-container']}>
            <div style={{ fontSize: '4rem' }}>{errorCode}</div>
            <div>{errorMessage || messages[errorCode] || 'An unexpected error occurred'}</div>
        </div>
    );
};

export default NotFound;
