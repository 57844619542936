// import library components
import api from "@/api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "@/Redux/utils";

type payloadType = {
    type: string,
    senior_debt?: number
    junior_debt?: number
    equity_value?: number
};

export const getTransitionFinanceFrameworkData: AsyncThunk<any, payloadType, {}> =
    createAsyncThunk(
        "getTransitionFinanceFrameworkData",
        async ({ type, senior_debt = 0, junior_debt = 0, equity_value = 0 }, { rejectWithValue }) => {
            try {
                const response = await fetch(
                    api.config.getTransitionFinanceFrameworkData(type, senior_debt, junior_debt, equity_value),
                    api.http.get()
                );
                const result = await api.afterFetchHandlers.parseContent(
                    response,
                    api.http.get()
                );
                api.afterFetchHandlers.checkStatus(response);
                return result;
            } catch (error: any) {
                if (!error.response) {
                    throw error;
                }
                rejectWithValue(error);
            }
        }
    );

const transitionFinancFrameworkData = createSlice({
    name: "transitionFinancFrameworkData",
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getTransitionFinanceFrameworkData.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getTransitionFinanceFrameworkData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getTransitionFinanceFrameworkData.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default transitionFinancFrameworkData.reducer;
