// currentStateOverviewData.slice.ts
import api from '../../../../api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { initialStateApi } from '../../../utils';

export const getCurrentStateOverviewData = createAsyncThunk(
    'getCurrentStateOverviewData',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.currentStateOverview(), api.http.get());
            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            api.afterFetchHandlers.checkStatus(response);
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const currentStateOverviewData = createSlice({
    name: 'currentStateOverview', 
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getCurrentStateOverviewData.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getCurrentStateOverviewData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getCurrentStateOverviewData.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default currentStateOverviewData.reducer;
