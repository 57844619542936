import { ROUTES } from './routes';

export const HEADER_CONFIG = {
    [ROUTES.DATA_COLLECTION.INDEX]: {
        title: 'CFPP Details',
        subTitle: 'Enter the details of CFPP',
    },
    [ROUTES.DATA_COLLECTION.DETAILS]: {
        title: 'CFPP Details',
        subTitle: 'Enter the details of CFPP',
    },
    [ROUTES.DATA_COLLECTION.TECHNICAL]: {
        title: 'CFPP Technical',
        subTitle: 'Enter the Technical details of CFPP',
    },
    [ROUTES.DATA_COLLECTION.COAL]: {
        title: 'CFPP Coal',
        subTitle: 'Enter the Fuel details of CFPP',
    },
    [ROUTES.DATA_COLLECTION.ECONOMICS]: {
        title: 'CFPP Economics',
        subTitle: 'Enter the Economical details of CFPP',
    },
    [ROUTES.DATA_COLLECTION.FINANCIALS]: {
        title: 'CFPP Financials',
        subTitle: 'Enter the Financial details of CFPP',
    },
    [ROUTES.DATA_COLLECTION.INVESTORS]: {
        title: 'CFPP Investors',
        subTitle: 'Enter the Investors details of CFPP',
    },
    [ROUTES.DATA_COLLECTION.TRANSITION]: {
        title: 'Transition Approach',
        subTitle: 'Enter the details for Transition Approach',
    },
    [ROUTES.CURRENT_STATE]: {
        title: 'Current State',
        subTitle: 'Shows the Current state of CFPP',
        rightSectionComponent: true,
    },
    [ROUTES.ECONOMIC_GAP]: {
        title: 'Economic Gap',
        subTitle: 'Shows the Transition plan of CFPP',
    },
    [ROUTES.CLEAN_ENERGY_TECH_MIX]: {
        title: 'Clean Energy Technology Mix',
        subTitle: 'Shows the Technical Insights on Clean Energy Technology Mix',
    },
    [ROUTES.TRANSITION_PLAN]: {
        title: 'Transition Plan',
        subTitle: 'Shows the Transition plan of CFPP',
    },
    [ROUTES.FINANCIALS.INDEX]: {
        title: 'Financials',
        subTitle: 'Financial details of CFPP',
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING]: {
        title: 'Financials With Transition Financing',
        subTitle: 'Shows the Transition Summary and Financials',
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.INDEX]: {
        title: 'With Transition Financing and Transition Credits',
        subTitle: 'Enter the details of Transition Financing and Credits',
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.ADDITIONALITY]: {
        title: 'Additionality of CFPP',
        subTitle: 'Additionality condition for eligibility of Transition Credits',
        backNav: true,
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.TRANSITION_CREDIT_CASHFLOW]: {
        title: 'Transition Credit Cashflow',
        subTitle: 'A detailed view of Transition Credits and its Financial outcomes',
        backNav: true,
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.PROJECT_DOCUMENT]: {
        title: 'Project Document',
        subTitle: '',
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.JUST_TRANSITION]: {
        title: 'Just Transition',
        subTitle: '',
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.SDG_GOALS]: {
        title: 'SDG Goals',
        subTitle: '',
    },
    [ROUTES.FINANCIALS.TRANSITION_FINANCING_AND_CREDITS.REPORTING]: {
        title: 'Reporting',
        subTitle: '',
    },
    [ROUTES.FINANCIAL_SUMMARY]: {
        title: 'Financial Summary',
        subTitle: 'Economic Gap, NPV, IRR and Equity IRR',
    },
    [ROUTES.TRANSITION_FINANCING.TRANSITION_FINANCE]: {
        title: 'Transition Finance',
        subTitle: 'Finance associated with the Current State of CFPP',
        backNav: false,
    },
    [ROUTES.TRANSITION_FINANCING.FUTURE_STATE]: {
        title: 'Transition Finance Framework',
        subTitle: 'Finance associated with the RE Transition',
        backNav: false,
    },
};
