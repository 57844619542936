import React from 'react';

interface Props {
    color?: React.CSSProperties['color'];
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
}

const FinancialsWithTFAndTcIcon: React.FC<Props> = ({
    color = '#666666',
    width = 20,
    height = 20,
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.0308 17.3121C2.58083 17.3121 2.19562 17.1331 1.87518 16.7751C1.55475 16.4171 1.39453 15.9868 1.39453 15.4841V4.51595C1.39453 4.01324 1.55475 3.58289 1.87518 3.22491C2.19562 2.86692 2.58083 2.68793 3.0308 2.68793H4.66707C5.11704 2.68793 5.50225 2.86692 5.82268 3.22491C6.14312 3.58289 6.30334 4.01324 6.30334 4.51595V15.4841C6.30334 15.9868 6.14312 16.4171 5.82268 16.7751C5.50225 17.1331 5.11704 17.3121 4.66707 17.3121H3.0308ZM3.0308 15.5069H4.66707V4.4931H3.0308V15.5069ZM9.57587 17.3121C9.1259 17.3121 8.74069 17.1331 8.42026 16.7751C8.09982 16.4171 7.9396 15.9868 7.9396 15.4841V4.51595C7.9396 4.01324 8.09982 3.58289 8.42026 3.22491C8.74069 2.86692 9.1259 2.68793 9.57587 2.68793H17.7572C18.2072 2.68793 18.5924 2.86692 18.9128 3.22491C19.2333 3.58289 19.3935 4.01324 19.3935 4.51595V15.4841C19.3935 15.9868 19.2333 16.4171 18.9128 16.7751C18.5924 17.1331 18.2072 17.3121 17.7572 17.3121H9.57587ZM9.57587 15.5069H17.7572V4.4931H9.57587V15.5069Z"
                fill={color}
            />
        </svg>
    );
};

export default FinancialsWithTFAndTcIcon;
