// moduleAccessGet.slice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api';
import { initialStateApi } from '../../../utils';

type PayloadType = {};

export const getModuleAccess = createAsyncThunk<any, PayloadType, {}>(
    'getModuleAccess',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.getmoduleAccess(), api.http.get());
            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const moduleAccessGet = createSlice({
    name: 'getModuleAccess', // Ensure this matches the key in rootReducer
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getModuleAccess.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getModuleAccess.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getModuleAccess.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default moduleAccessGet.reducer;
