import api from "@/api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

import { initialStateApi } from "@/Redux/utils";

type payloadType = {
  params?: Record<string, (string | any)>;
};

export const postTfSave: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "postTfSave",
    async ({params}, { rejectWithValue }) => {
      try {
        
        const response = await fetch(
          api.config.postTfSave(params),
          api.http.get()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );

        api.afterFetchHandlers.checkStatus(response);
        return result;

      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  );

const tfSave = createSlice({
  name: "tfSave",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postTfSave.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(postTfSave.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(postTfSave.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default tfSave.reducer;
