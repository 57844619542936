import api from '@/api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '../../utils';
import { toast } from 'react-toastify';

type payloadType = {
    payload: Record<string, any>;
};

export const addPolygonDataAction: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'addPolygonDataAction',
    async ({  payload }, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.addPolygonData(), api.http.post(payload));

            const result = await api.afterFetchHandlers.checkStatus(response);

            return result;
        } catch (error: any) {
            if (!error.ok) {
                const result = (await api.afterFetchHandlers.parseContent(error, api.http.post(payload))) || {};
                toast.error(result.message || 'Failed to save!');
            }
            rejectWithValue(error);
        }
    }
);

const addPolygonData = createSlice({
    name: 'addPolygonData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(addPolygonDataAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(addPolygonDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(addPolygonDataAction.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.payload;
                state.isError = true;
            });
    },
});

export default addPolygonData.reducer;
