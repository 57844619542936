import api from '../../../../api';
import { createSlice, createAsyncThunk, SerializedError, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { message } from 'antd';
import { initialStateApi } from '../../../utils';
import { toast } from 'react-toastify';

export const getEquityAndLiability = createAsyncThunk(
    'getEquityAndLiability',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.equityAndLiability(), api.http.get());

            const result = await api.afterFetchHandlers.parseContent(response, api.http.get());
            api.afterFetchHandlers.checkStatus(response);

            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            rejectWithValue(error);
        }
    }
);

const equityAndLiability = createSlice({
    name: 'equityAndLiability',
    initialState: initialStateApi,
    reducers: {
        updateEquityLiabilityData(state, action) {
            state.response.data.equityAndLiability.equityAndLiability.value=66
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getEquityAndLiability.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getEquityAndLiability.fulfilled, (state, action:any) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getEquityAndLiability.rejected, (state, action:any) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});
export const { updateEquityLiabilityData } = equityAndLiability.actions;
export default equityAndLiability.reducer;
