import React, { forwardRef } from 'react';
import styles from './JustTransition.module.scss';
import { ReactComponent as NotFoundImg } from '@/assets/icons/notFoundImg.svg';

const JustTransition = forwardRef<HTMLDivElement>((props, ref) => {
    return (
        <div ref={ref} className={styles['just-transition-container']}>
            <NotFoundImg />
        </div>
    );
});

export default JustTransition;