import api from '../../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { initialStateApi } from '../../../utils';
import { toast } from 'react-toastify';

type payloadType = any;

export const getCreditRating: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'getCreditRating',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetch(api.config.CreditRating(), api.http.get());
            const result = await api.afterFetchHandlers.parseContent(
                response,
                api.http.get()
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const CreditRatingData = createSlice({
    name: 'CreditRatingData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getCreditRating.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getCreditRating.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getCreditRating.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default CreditRatingData.reducer;
