import currentStateOverviewReducer from './overview/getOverviewDetails.slice';
import powerGenerationReducers from './powerGeneration/powerGeneration.slice';
import profitAnalysisReducer from './profitAnalysis/getProfitAnalysis.slice';
import equityAndLiability from './equityAndLiability/equityAndLiability.slice';
import financialAssets from './financialAssets/financialAssets.slice';
import sensitivityAnalysis from './sensitivityAnalysisSave/sensitivityAnalysisSave.slice';
import revenueAndExpenses from './revenueAndExpenses/revenueAndExpenses.slice';
import resetSensitivityAnalysisState from './reset/reset.slice';
import assumptionsReducer from './assumptions/assumptions.slice';
import CreditRatingData from './CreditRating/CreditRating.slice';

const currentStateCombinedReducers = {
    currentStateOverview: currentStateOverviewReducer,
    profitAnalysis: profitAnalysisReducer,
    equityAndLiability: equityAndLiability,
    powerGenerationData: powerGenerationReducers.powerGenerationData,
    financialAssets: financialAssets,
    sensitivityAnalysis: sensitivityAnalysis,
    revenueAndExpensesData: revenueAndExpenses,
    resetSensitivityAnalysisState: resetSensitivityAnalysisState,
    assumptions: assumptionsReducer,
    CreditRatingData
};

export default currentStateCombinedReducers;
