import api from '../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

import { initialStateApi } from '../../utils';

type payloadType = any

export const getReFinancialsData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getReFinancialsData",
    async (payload, { rejectWithValue }) => {
        try {
      
            const response = await fetch(api.config.reFinancialsData(), api.http.post(payload));
            const result = await api.afterFetchHandlers.parseContent(response, api.http.post(payload));
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const reFinancialsData = createSlice({
    name: 'reFinancialsData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getReFinancialsData.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getReFinancialsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getReFinancialsData.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default reFinancialsData.reducer;
