import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './Redux/store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

const customTheme = {
    components: {
        Input: {
            borderRadius: 2,
        },
        InputNumber: {
            addonBg: '#F4F5F7',
        },
    },
    token: {
        borderRadius: 2,
    },
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
    <ConfigProvider theme={customTheme}>
        <Provider store={store}>
            <App />
        </Provider>
    </ConfigProvider>
    </React.StrictMode>
);

reportWebVitals();
