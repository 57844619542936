import React, { useRef, useEffect } from 'react';
import './SidebarSubTabs.scss';
import { useNavigate } from 'react-router-dom';
import SmallArrow from '@/assets/icons/smallArrow';
import { useLocation } from 'react-router-dom';

type SidebarSubTab = {
    key: string;
    title: string;
};

type SidebarSubTabsProps = {
    path: string;
    activeSubTab: string | undefined;
    subTabs: SidebarSubTab[];
    isExpanded: boolean;
};

function SidebarSubTabs(props: SidebarSubTabsProps) {
    const location = useLocation();
    let { path, activeSubTab, subTabs, isExpanded } = props;

    const tab = location.pathname.split('/').filter(Boolean);
    const activeSubSubTab = tab[2];
    if (activeSubSubTab) isExpanded = activeSubSubTab == activeSubTab ? true : false;

    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            if (isExpanded) {
                containerRef.current.style.height = `${containerRef.current.scrollHeight}px`;
                setTimeout(() => {
                    if (containerRef.current) {
                        containerRef.current.style.height = 'auto';
                    }
                }, 300);
            } else {
                containerRef.current.style.height = `${containerRef.current.scrollHeight}px`;
                setTimeout(() => {
                    if (containerRef.current) {
                        containerRef.current.style.height = '0';
                    }
                }, 0);
            }
        }
    }, [isExpanded, subTabs]);
    
    return (
        <div className={`sub-tabs-container ${isExpanded ? '' : 'collapsed'}`} ref={containerRef}>
            <div className="vertical-line" />
            <div className="sub-tabs">
                {subTabs?.map(({ key, title }) => {
                    return (
                        <div
                            key={key}
                            className={`sub-tab ${activeSubTab === key ? 'active' : ''}`}
                            onClick={() => navigate(`${path}/${key}`)}
                        >
                            {key === activeSubTab ? (
                                <SmallArrow
                                    style={{
                                        marginRight: '0.2rem',
                                    }}
                                    width={'0.6rem'}
                                    height={'0.6rem'}
                                    arrowType="right"
                                    color="#0171d6"
                                />
                            ) : null}

                            <div className="title">{title}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SidebarSubTabs;
