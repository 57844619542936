import api from "@/api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

import { initialStateApi } from "@/Redux/utils";

type payloadType = {
  params?: Record<string, (string | any)>;
};

export const getGridConnectivityData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getGridConnectivityData",
    async ({params}, { rejectWithValue }) => {
      try {
        
        const response = await fetch(
          api.config.gridConnectivity(params),
          api.http.get()
        );

        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        return rejectWithValue(error.message);
      }
    }
  );

const gridConnectivity = createSlice({
  name: "gridConnectivity",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getGridConnectivityData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getGridConnectivityData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getGridConnectivityData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default gridConnectivity.reducer;
