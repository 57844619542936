import React from 'react';
import CrossIcon from '@/assets/icons/crossIcon';
import { ReactComponent as AlertIcon } from '@/assets/icons/alert.svg';
import styles from './AlertPopup.module.scss';
import { useNavigate, NavigateProps, Blocker as RouterBlocker } from 'react-router-dom';
interface AlertPopupProps {
    blocker: RouterBlocker;
    buttonText: string;
    message?: string;
    navigateProps: NavigateProps;
}

const AlertPopup: React.FC<AlertPopupProps> = ({ blocker, buttonText, navigateProps, message }) => {
    const navigate = useNavigate();
    const handleSkip = () => {
        blocker.proceed?.();
    };
    const onContinue = () => {
        blocker.reset?.();
        navigate(navigateProps.to, navigateProps);
    };
    const handleClose = () => {
        blocker.reset?.();
    };
    return (
        <div className={styles.containerWrap}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.title}>Note</div>
                    <CrossIcon width={15} height={15} onClick={handleClose} />
                </div>

                <div className={styles.body}>
                    <p
                        dangerouslySetInnerHTML={{
                            __html:
                                message ||
                                `Financial outcomes for the next module will not be accurate if modules are skipped.<br />Would you like to continue your progress?`,
                        }}
                    />
                    <AlertIcon />
                </div>

                <div className={styles.footer}>
                    <button
                        className={`${styles.btn} ${styles['secondary-btn']}`}
                        onClick={handleSkip}
                    >
                        Skip Anyway
                    </button>

                    <button className={styles.btn} onClick={onContinue}>
                        {buttonText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AlertPopup;

// Financial outcomes for the next module will not be accurate if modules are skipped.
// Would you like to continue your progress?
