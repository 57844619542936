//transitionPlanData.slice.ts
import api from '../../../api';
import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';

//  initial state
import { initialStateApi } from '../../utils';

type payloadType = {
    apiEndpoint?: string;
    method?:string
};

export const getTransitionPlanData: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'getGraphDataForDates',
    async (params: payloadType, { rejectWithValue }) => {
        try {
            const {  method } = params;
            const url = api.config.transitionPlanData(
                method,
            );
            const response = await fetch(url, api.http.get());
            const result = await api.afterFetchHandlers.parseContent(response, api.http.getNoAuth());
            api.afterFetchHandlers.checkStatus(response);
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const transitionPlanData = createSlice({
    name: 'transitionPlanData',
    initialState: initialStateApi,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getTransitionPlanData.pending, (state, _) => {
                state.isLoading = true;
            })
            .addCase(getTransitionPlanData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(getTransitionPlanData.rejected, (state, action) => {
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default transitionPlanData.reducer;
