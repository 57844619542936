import React from 'react';

interface Props {
    color?: React.CSSProperties['color'];
    height?: number;
    width?: number;
}

const TransitionPlan: React.FC<Props> = ({ color = '#666666', width = 20, height = 20 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.7159 19.0909C15.7367 19.0909 19.8068 15.0208 19.8068 10C19.8068 4.97926 15.7367 0.909119 10.7159 0.909119C5.69514 0.909119 1.625 4.97926 1.625 10C1.625 15.0208 5.69514 19.0909 10.7159 19.0909ZM8.11591 9.34644C6.72847 6.32794 8.78529 3.32182 14.3472 3.66281C14.9945 6.65157 13.1667 9.07847 9.66156 9.88172L9.47901 10.3807H13.5926L8.87048 18.5228L11.122 12.0116H7.08141L11.2072 5.73158C9.54049 6.15554 8.26945 7.42843 8.11591 9.34644Z"
                fill={color}
            />
        </svg>
    );
};

export default TransitionPlan;
