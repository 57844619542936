// import library components
import api from "../../../api";
import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";

//  initial state
import { initialStateApi } from "../../utils";

type payloadType = {
};

export const getDropdownData: AsyncThunk<any, payloadType, {}> =
  createAsyncThunk(
    "getDropdownData",
    async (_, { rejectWithValue }) => {
      try {
        const response = await fetch(
          api.config.getDropdowns(),
          api.http.get()
        );
        const result = await api.afterFetchHandlers.parseContent(
          response,
          api.http.get()
        );
        return result;
      } catch (error: any) {
        if (!error.response) {
          throw error;
        }
        rejectWithValue(error);
      }
    }
  );

const dropdownData = createSlice({
  name: "dropdownData",
  initialState: initialStateApi,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDropdownData.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(getDropdownData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.response = action.payload;
      })
      .addCase(getDropdownData.rejected, (state, action) => {
        state.isLoading = false;
        state.errorResponse = action.error;
      });
  },
});

export default dropdownData.reducer;
