import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import api from '@/api';
import { initialStateApi } from '@/Redux/utils';

type paramsTypes = {
    type: string;
    payload?: {};
};

type payloadType = any;

export const updateInvestorDetailsDataAction: AsyncThunk<any, payloadType, {}> = createAsyncThunk(
    'updateInvestorDetailsDataAction',
    async (params: paramsTypes, { rejectWithValue }) => {
        const { type, payload } = params;

        try {
            const url = api.config?.getReInvestorDetailsData(type);
            const response = await fetch(url, api.http.patch(payload));
            const result = await api?.afterFetchHandlers?.parseContent(
                response,
                api.http.patch(payload)
            );
            return result;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error);
        }
    }
);

const investorDetailsDataReducer = createSlice({
    name: 'investorDetailsDataReducer',
    initialState: initialStateApi,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateInvestorDetailsDataAction.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updateInvestorDetailsDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.response = action.payload;
            })
            .addCase(updateInvestorDetailsDataAction.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorResponse = action.error;
            });
    },
});

export default investorDetailsDataReducer.reducer;
