import React from 'react';

const arrowTypes = {
    up: {
        rotate: 0,
    },
    down: {
        rotate: 180,
    },
    left: {
        rotate: 270,
    },
    right: {
        rotate: 90,
    },
};

interface Props {
    style?: React.CSSProperties;
    color?: string;
    height?: string | number;
    width?: string | number;
    arrowType: keyof typeof arrowTypes;
}

const SmallArrow: React.FC<Props> = ({
    style,
    color = '#666666',
    height = 9,
    width = 14,
    arrowType = 'up',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 14 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: `rotate(${arrowTypes[arrowType].rotate}deg)`,
                ...style,
            }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.777268 7.94148C1.16779 8.33201 1.80096 8.33201 2.19148 7.94148L6.95279 3.18017L11.7141 7.94148C12.1046 8.33201 12.7378 8.33201 13.1283 7.94148C13.5188 7.55096 13.5188 6.91779 13.1283 6.52727L7.6599 1.05885C7.26938 0.668325 6.63621 0.668325 6.24569 1.05885L0.777268 6.52727C0.386744 6.91779 0.386744 7.55096 0.777268 7.94148Z"
                fill={color}
            />
        </svg>
    );
};

export default SmallArrow;
