import React from 'react';

interface Props {
    color?: React.CSSProperties['color'];
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
}

const FinancialsWithTFIcon: React.FC<Props> = ({ color = '#666666', width = 20, height = 20 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.62147 17.3121V15.5069H9.25774V4.51595H7.62147V2.68793H9.25774C9.70771 2.68793 10.0929 2.86692 10.4134 3.22491C10.7338 3.58289 10.894 4.01324 10.894 4.51595V15.4841C10.894 15.9868 10.7338 16.4171 10.4134 16.7751C10.0929 17.1331 9.70771 17.3121 9.25774 17.3121H7.62147ZM14.1665 17.3121C13.7166 17.3121 13.3314 17.1331 13.0109 16.7751C12.6905 16.4171 12.5303 15.9868 12.5303 15.4841V4.51595C12.5303 4.01324 12.6905 3.58289 13.0109 3.22491C13.3314 2.86692 13.7166 2.68793 14.1665 2.68793H18.2572C18.7072 2.68793 19.0924 2.86692 19.4128 3.22491C19.7333 3.58289 19.8935 4.01324 19.8935 4.51595V15.4841C19.8935 15.9868 19.7333 16.4171 19.4128 16.7751C19.0924 17.1331 18.7072 17.3121 18.2572 17.3121H14.1665ZM14.1665 15.5069H18.2572V4.51595H14.1665V15.5069ZM5.16707 13.656L4.00123 12.3764L5.28979 10.914H1.89453V9.08599H5.28979L4.00123 7.64643L5.16707 6.34396L8.4396 10L5.16707 13.656Z"
                fill={color}
            />
        </svg>
    );
};

export default FinancialsWithTFIcon;
