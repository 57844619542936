import React from 'react';

interface Props {
    color?: React.CSSProperties['color'];
    height?: React.CSSProperties['height'];
    width?: React.CSSProperties['width'];
}

const FutureStateIcon: React.FC<Props> = ({ color = '#666666', width = 20, height = 20 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1538_34080)">
                <path
                    d="M0.986328 19.0137V17.0106L2.98937 15.0076V19.0137H0.986328ZM4.9924 19.0137V13.0046L6.99544 11.0015V19.0137H4.9924ZM8.99848 19.0137V11.0015L11.0015 13.0296V19.0137H8.99848ZM13.0046 19.0137V13.0296L15.0076 11.0266V19.0137H13.0046ZM17.0106 19.0137V8.99848L19.0137 6.99544V19.0137H17.0106ZM0.986328 13.8308V11.0015L7.99696 3.99089L12.003 7.99696L19.0137 0.986328V3.81562L12.003 10.8263L7.99696 6.82018L0.986328 13.8308Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_1538_34080">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FutureStateIcon;
