// routes.ts
export const ROUTES = {
    DATA_COLLECTION: {
        INDEX: '/dataCollection',
        DETAILS: '/dataCollection/details',
        TECHNICAL: '/dataCollection/technical',
        COAL: '/dataCollection/coal',
        ECONOMICS: '/dataCollection/economics',
        FINANCIALS: '/dataCollection/cfppfinancials',
        INVESTORS: '/dataCollection/investors',
        TRANSITION: '/dataCollection/transition',
    },
    CURRENT_STATE: '/current-state',
    TRANSITION_PLAN: '/transition-plan',
    ECONOMIC_GAP: '/economic-gap',
    CLEAN_ENERGY_TECH_MIX: '/clean-energy-tech-mix',
    TRANSITION_FINANCING: {
        TRANSITION_FINANCE:'/transition-financing/transition-finance',
        FUTURE_STATE:'/transition-financing/transition-financing-future-state'
    },
    // TRANSITION_SUMMARY: '/transition-summary',
    FINANCIALS: {
        INDEX: '/financials',
        TRANSITION_FINANCING: '/financials/transtion-financing',
        TRANSITION_FINANCING_AND_CREDITS: {
            INDEX: '/financials/transtion-financing-transtion-credits',
            ADDITIONALITY: '/financials/transtion-financing-transtion-credits/additionality',
            TRANSITION_CREDIT_CASHFLOW:
                '/financials/transtion-financing-transtion-credits/transition-credit-cashflow',
            PROJECT_DOCUMENT: '/financials/transtion-financing-transtion-credits/project-document',
            JUST_TRANSITION: '/financials/transtion-financing-transtion-credits/just-transition',
            SDG_GOALS: '/financials/transtion-financing-transtion-credits/sdg-goals',
            REPORTING: '/financials/transtion-financing-transtion-credits/reporting',
        },
    },
    FINANCIAL_SUMMARY:'/financial-summary',
};
